<template>
  <div class="container">
    <div class="container-content">
      <div class="table-header">
        <h5>{{ $t("labels.transactions") }}</h5>
        <div class="flex-box">
          <div class="filters">
            <Multiselect
              v-model="filters.type"
              :options="transactionTypes"
              :placeholder="$t('labels.type')"
              @input="filterType"
            />
            <date-picker
              v-model="date"
              :placeholder="$t('labels.period')"
              range
              valueType="format"
              @clear="
                (clear) => {
                  filters.created_at = null;
                }
              "
              @input="filterDate"
            ></date-picker>
            <DropdownFilter
              fetch-method="getStores"
              :placeholder="$t('labels.shops')"
              @filterChange="(val) => setFilterValue('storeid', val)"
              image-cont="brand.avatar"
            />
            <!--<Multiselect
              class="shops"
              v-model="filters.storeid"
              :options="stores"
              :placeholder="$t('labels.shop')"
              trackBy="name"
              valueProp="id"
              mode="multiple"
              :closeOnSelect="false"
              :searchable="isSearchable"
              @input="filterStore"
              @open="isSearchable = true"
              @close="isSearchable = false"
            >
              <template #multiplelabel="{ values }">
                <div class="custom-label">
                  {{ $t("labels.shop") }} <span>{{ values.length }}</span>
                </div>
              </template>
              <template #option="{ option }">
                <div class="select-option">
                  <img :src="option.brand.avatar" :alt="option.name" />
                  <p>{{ option.name }}</p>
                </div>
              </template>
              <template #afterlist>
                <div class="select-pagination">
                  <a
                    class="select-pagination__prev"
                    :class="{ isDisabled: storesPagination.perPage <= 20 }"
                    @click="loadPrevStores"
                    >{{ $t("labels.prev") }}</a
                  >
                  <a class="select-pagination__counter">{{
                    `${storesPagination.perPage}/${storesPagination.total}`
                  }}</a>
                  <a
                    class="select-pagination__next"
                    :class="{
                      isDisabled:
                        storesPagination.perPage >= storesPagination.total,
                    }"
                    @click="loadMoreStores"
                    >{{ $t("labels.next") }}</a
                  >
                </div>
              </template>
            </Multiselect>-->
            <Multiselect
              v-model="amount"
              :options="amountValues"
              :placeholder="$t('labels.amount')"
              mode="multiple"
              :max="2"
              :closeOnSelect="false"
              @clear="
                (clear) => {
                  filters.amount = null;
                }
              "
              @input="filterAmount"
            >
              <template #multiplelabel="{ values }">
                <p class="small amount">
                  {{
                    `${values[0].label} ~ ${values[1] ? values[1].label : ""}`
                  }}
                </p>
              </template>
            </Multiselect>
          </div>
          <div
            class="flex-box__inner print-export"
            :class="{
              'is-disabled':
                transactions.length <= 0 || $store.state.state.isGlobalLoading,
            }"
          >
            <!--<div class="print-box" @click="print">
              <a>{{ $t("labels.print") }}</a>
              <svg
                class="icon"
                width="18"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.37 2.933h-2.974L12.619.52a.773.773 0 0 0-.72-.52H6.082a.749.749 0 0 0-.72.52l-.777 2.413H1.63C.72 2.933 0 3.657 0 4.529v5.309c0 .89.739 1.596 1.63 1.596h.265l-1.156 3.62a.672.672 0 0 0 .114.649c.132.186.36.297.606.297h15.082a.768.768 0 0 0 .606-.297.725.725 0 0 0 .114-.65l-1.156-3.6h.265c.91 0 1.63-.724 1.63-1.597V4.53c0-.891-.739-1.596-1.63-1.596Zm-1.155 1.874a.86.86 0 0 1 .871.854.86.86 0 0 1-.871.854.873.873 0 0 1-.872-.854c0-.464.398-.854.872-.854ZM6.65 1.467h4.717l.474 1.447H6.177l.474-1.448ZM2.482 14.533 4.377 8.63h9.246l1.895 5.903H2.482Z"
                  fill="#5C3D2E"
                />
                <path
                  d="M6.234 10.988h5.513c.341 0 .607-.26.607-.594a.593.593 0 0 0-.607-.594H6.234a.593.593 0 0 0-.607.594c0 .335.284.594.607.594ZM12.543 12.158H5.457a.593.593 0 0 0-.606.594c0 .334.265.594.606.594h7.086c.341 0 .607-.26.607-.594a.604.604 0 0 0-.607-.594Z"
                  fill="#5C3D2E"
                />
              </svg>
            </div>-->
            <div class="export-box" @click="exportTransaction">
              <a>{{ $t("labels.export") }}</a>
              <svg
                class="icon"
                width="15"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="m8.326.344 3.183 3.121c1.281 1.284-.64 3.21-1.921 1.926l-.013-.013c-.394-.4-.716-.268-.716.297v2.498c0 .75-.612 1.359-1.367 1.359h.016a1.362 1.362 0 0 1-1.367-1.36V5.676c0-.564-.32-.698-.716-.297l-.013.013C4.132 6.675 2.21 4.75 3.491 3.465L6.63.35A1.21 1.21 0 0 1 8.325.344ZM3.067 13.277h8.866c.19 0 .34-.151.34-.338v-1.705a1.363 1.363 0 0 1 2.727 0v3.404C15 15.39 14.39 16 13.636 16H1.364C.61 16 0 15.39 0 14.638v-3.404a1.363 1.363 0 0 1 2.727 0v1.705a.34.34 0 0 0 .34.338Z"
                  fill="#5C3D2E"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <SkeletonLoader v-if="$store.state.state.isGlobalLoading" type="table" />

      <TransactionsTable v-else :transactions="transactions" id="printableTr" />

      <div class="pagination">
        <a
          v-if="pagination.page < pagination.total"
          @click="loadMoreTransactions"
          class="load-more"
          :class="{ 'load-more--disabled': isLoadingMore }"
          >{{ $t("labels.loadMoreTransactions") }}
          <svg v-if="isLoadingMore" class="spinner" viewBox="0 0 50 50">
            <circle
              class="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "@vueform/multiselect";
import TransactionsTable from "@/components/tables/TransactionsTable";
import { formatCurrency, removeFalsy } from "@/libs/helpers";
import { formatDistance, format, parseISO } from "date-fns";
import { useHead } from "@vueuse/head";

export default {
  name: "Transactions",
  components: {
    Multiselect,
    DatePicker,
    TransactionsTable,
  },
  data() {
    return {
      columns: [
        {
          key: "brand",
          title: this.$t("labels.brand"),
        },
        {
          key: "shop",
          title: this.$t("labels.shop"),
        },
        {
          key: "created_at",
          title: this.$t("labels.time"),
        },
        {
          key: "amount",
          title: this.$t("labels.amount"),
        },
        {
          key: "redirect",
          title: "",
        },
      ],
      transactions: [],
      pagination: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      storesPagination: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      filters: {
        type: null,
        created_at: null,
        storeid: null,
        amount: null,
      },
      date: [],
      amount: [],
      transactionTypes: [
        { label: this.$t("labels.charge"), value: "charge" },
        { label: this.$t("labels.cashback"), value: "cashback" },
      ],
      amountValues: [
        { label: "100", value: "100" },
        { label: "500", value: "500" },
        { label: "1000", value: "1000" },
        { label: "2000", value: "2000" },
        { label: "4000", value: "4000" },
        { label: "8000", value: "8000" },
        { label: "12000", value: "12000" },
        { label: "16000", value: "16000" },
      ],
      stores: [],
      isSearchable: false,
      isLoadingMore: false,
    };
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.transactions")}`,
    });
  },
  async created() {
    await this.getStores();
    await this.getTransactions();
  },
  methods: {
    async setFilterValue(col, val) {
      this.filters[col] = val;
      await this.resetTransactions();
      await this.getTransactions();
    },
    goToTransaction(item) {
      this.$router.push(`/transactions/${item}`);
    },
    formatTime(date) {
      return `${formatDistance(new Date(), new Date(date))} ago`;
    },
    formatTimeReadable(date) {
      return format(parseISO(date), "yyyy-MM-dd");
    },
    formatMoney(amount, type) {
      return type === "charge"
        ? `- ${formatCurrency(amount)}`
        : `+ ${formatCurrency(amount)}`;
    },
    print() {
      this.$htmlToPaper("printableTr");
      //window.print();
    },
    exportTransaction() {
      this.$store
        .dispatch(
          "exportTransactions",
          removeFalsy({
            mime: "csv",
            params: {
              status: "success",
              page: this.pagination.page,
              per_page: this.pagination.perPage,
              type: this.filters.type,
              created_at: this.filters.created_at,
              "store|id": this.filters.storeid,
              amount: this.filters.amount,
            },
          })
        )
        .then((res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "transactions.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((err) => {
          return err;
        });
    },
    getTransactions() {
      if (this.pagination.page === 1) {
        this.$store.commit("SET_GLOBAL_LOADING", true);
      }
      this.isLoadingMore = true;
      this.$store
        .dispatch(
          "getTransactions",
          removeFalsy({
            params: {
              page: this.pagination.page,
              per_page: this.pagination.perPage,
              type: this.filters.type,
              created_at: this.filters.created_at,
              "store|id": this.filters.storeid,
              amount: this.filters.amount,
            },
          })
        )
        .then((res) => {
          setTimeout(() => {
            this.$store.commit("SET_GLOBAL_LOADING", false);
            this.isLoadingMore = false;
          }, 1500);
          let response = res.data.data;
          this.transactions = this.transactions.concat(response);
          this.pagination.total = res.data.meta.last_page;
        })
        .catch((err) => {
          return err;
        });
    },
    getStores() {
      this.$store
        .dispatch("getStores", {
          params: {
            page: this.storesPagination.page,
            per_page: this.storesPagination.perPage,
          },
        })
        .then((res) => {
          this.stores = res.data.data;
          this.storesPagination.total = res.data.meta.total;
        })
        .catch((err) => {
          return err;
        });
    },
    loadMoreTransactions() {
      if (!this.isLoadingMore) {
        this.pagination.page++;
        this.getTransactions();
      }
    },
    loadMoreStores() {
      this.storesPagination.perPage += 20;
      this.getStores();
    },
    resetTransactions() {
      this.pagination = {
        page: 1,
        perPage: 20,
        total: 0,
      };
      this.transactions = [];
    },
    loadPrevStores() {
      this.storesPagination.perPage -= 20;
      this.getStores();
    },
    async filterType(val) {
      this.filters.type = val;
      await this.resetTransactions();
      await this.getTransactions();
    },
    async filterDate(val) {
      if (val[0] === null && val[1] === null) {
        this.filters.created_at = null;
      } else {
        this.filters.created_at = [
          {
            start: val[0],
          },
          {
            end: val[1],
          },
        ];
      }
      await this.resetTransactions();
      await this.getTransactions();
    },
    async filterAmount(val) {
      if (!val.length) {
        this.filters.amount = null;
      } else {
        this.filters.amount = [
          {
            start: parseFloat(val[0]),
          },
          {
            end: parseFloat(val[1]),
          },
        ];
      }
      if (val.length >= 2 || !val.length) {
        await this.resetTransactions();
        await this.getTransactions();
      }
    },
    async filterStore(val) {
      this.filters.storeid = val;
      await this.resetTransactions();
      await this.getTransactions();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &-content {
    .brand {
      display: flex;
      align-items: center;
    }
    .table-header {
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      @media (max-width: 1300px) {
        flex-direction: column;
        align-items: flex-start;
      }
      h5 {
        margin-right: 60px;
        @media (max-width: 1300px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
      .flex-box {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1124px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .filters {
          display: flex;
          align-items: center;
          @media (max-width: 1124px) {
            width: 100%;
          }
          @media (max-width: 768px) {
            display: grid;
            grid-template-columns: 6fr 6fr;
            grid-gap: 16px;
            width: 100%;
            div {
              &:not(:last-child) {
                margin-right: 0 !important;
              }
            }
            .mx-datepicker-range {
              width: 100%;
            }
            @media (max-width: 500px) {
              grid-template-columns: 12fr;
            }
          }
          .multiselect {
            width: fit-content;

            @media (max-width: 1125px) {
              width: calc(100% / 4);
            }

            @media (max-width: 768px) {
              width: 100%;
            }
            &.shops {
              min-width: 170px;
              ::v-deep .multiselect-dropdown {
                overflow: hidden;
              }
              ::v-deep .multiselect-options {
                overflow: scroll;
                padding-bottom: 10px;
              }
            }
            .custom-label {
              width: fit-content;
              padding-left: 15px;
              display: flex;
              align-items: center;
              font-size: 14px;
              color: $wood_bark;
              font-family: Rubik;
              margin-right: auto;
              span {
                margin-left: 5px;
                min-width: 20px;
                min-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                color: $white;
                background-color: $smoke_tree;
                border-radius: 50px;
                font-weight: 700;
                font-family: Rubik;
              }
            }
            .select-option {
              display: flex;
              align-items: center;
              img {
                margin-right: 10px;
                display: block;
                width: 30px;
              }
              p {
                font-size: 12px;
              }
            }
            .select-pagination {
              position: fixed;
              left: 0;
              right: 0;
              bottom: 0;
              border-top: 1px solid $calico;
              background-color: $white;
              display: grid;
              grid-template-columns: 3fr 6fr 3fr;
              a {
                padding: 5px;
                font-size: 14px;
                transition: 0.3s;
                &.select-pagination {
                  &__prev {
                    border-right: 1px solid $calico;
                    &.isDisabled {
                      pointer-events: none;
                      opacity: 0.5;
                    }
                    &:hover {
                      background-color: $calico;
                      color: $wood_bark;
                    }
                  }
                  &__counter {
                    text-align: center;
                  }
                  &__next {
                    border-left: 1px solid $calico;
                    &.isDisabled {
                      pointer-events: none;
                      opacity: 0.5;
                    }
                    &:hover {
                      background-color: $calico;
                      color: $wood_bark;
                    }
                  }
                }
              }
            }
            .amount {
              margin: 0 5px 0 15px;
            }
          }
          div {
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
        &__inner {
          margin-left: 20px;
          display: flex;
          align-items: center;
          @media (max-width: 1124px) {
            margin-left: auto;
            margin-top: 20px;
          }
          .print-box {
            cursor: pointer;
            margin-right: 30px;
            align-items: center;
            display: flex;
            a {
              font-family: Rubik;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
            .icon {
              margin-left: 10px;
            }
          }
          .export-box {
            cursor: pointer;
            align-items: center;
            display: flex;
            a {
              font-family: Rubik;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
            .icon {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .pagination {
      margin-bottom: 37px;
      .load-more {
        box-sizing: border-box;
        cursor: pointer;
        margin-top: 40px;
        width: fit-content;
        padding: 16px 23px;
        display: flex;
        justify-content: center;
        border: 1px solid $calico;
        border-radius: 8px;
        font-size: 14px;
        min-width: 39px;
        &:not(:last-child) {
          margin-right: 16px;
        }
        @media (max-width: 500px) {
          margin-top: 20px;
          padding: 10px 12px;
          margin-right: 0 !important;
        }
        &--disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}

.readable {
  display: none !important;
}

.is-disabled {
  opacity: 0.55;
  pointer-events: none !important;
}
</style>
