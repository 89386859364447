<template>
  <div class="container">
    <div class="container-content">
      <div class="container-content__header">
        <a @click="$router.go(-1)" class="back"
          ><svg
            width="8"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1 1 7l6 6"
              stroke="#B85C38"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t("labels.back") }}</a
        >
        <div class="container-content__header--action">
          <div class="switcher">
            <a
              class="switcher__inner"
              :class="{ active: activeSwitcher === 'list' }"
              @click="activeSwitcher = 'list'"
              >{{ $t("labels.list") }}</a
            >
            <a
              class="switcher__inner"
              :class="{ active: activeSwitcher === 'map' }"
              @click="activeSwitcher = 'map'"
              >{{ $t("labels.map") }}</a
            >
          </div>
          <div class="actions">
            <!--<div class="actions__inner" @click="print">
              <a>{{ $t("labels.print") }}</a>
              <svg
                class="icon"
                width="18"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.37 2.933h-2.974L12.619.52a.773.773 0 0 0-.72-.52H6.082a.749.749 0 0 0-.72.52l-.777 2.413H1.63C.72 2.933 0 3.657 0 4.529v5.309c0 .89.739 1.596 1.63 1.596h.265l-1.156 3.62a.672.672 0 0 0 .114.649c.132.186.36.297.606.297h15.082a.768.768 0 0 0 .606-.297.725.725 0 0 0 .114-.65l-1.156-3.6h.265c.91 0 1.63-.724 1.63-1.597V4.53c0-.891-.739-1.596-1.63-1.596Zm-1.155 1.874a.86.86 0 0 1 .871.854.86.86 0 0 1-.871.854.873.873 0 0 1-.872-.854c0-.464.398-.854.872-.854ZM6.65 1.467h4.717l.474 1.447H6.177l.474-1.448ZM2.482 14.533 4.377 8.63h9.246l1.895 5.903H2.482Z"
                  fill="#5C3D2E"
                />
                <path
                  d="M6.234 10.988h5.513c.341 0 .607-.26.607-.594a.593.593 0 0 0-.607-.594H6.234a.593.593 0 0 0-.607.594c0 .335.284.594.607.594ZM12.543 12.158H5.457a.593.593 0 0 0-.606.594c0 .334.265.594.606.594h7.086c.341 0 .607-.26.607-.594a.604.604 0 0 0-.607-.594Z"
                  fill="#5C3D2E"
                />
              </svg>
            </div>-->
            <!-- <div class="actions__inner">
              <a>{{ $t("labels.export") }}</a>
              <svg
                class="icon"
                width="15"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="m8.326.344 3.183 3.121c1.281 1.284-.64 3.21-1.921 1.926l-.013-.013c-.394-.4-.716-.268-.716.297v2.498c0 .75-.612 1.359-1.367 1.359h.016a1.362 1.362 0 0 1-1.367-1.36V5.676c0-.564-.32-.698-.716-.297l-.013.013C4.132 6.675 2.21 4.75 3.491 3.465L6.63.35A1.21 1.21 0 0 1 8.325.344ZM3.067 13.277h8.866c.19 0 .34-.151.34-.338v-1.705a1.363 1.363 0 0 1 2.727 0v3.404C15 15.39 14.39 16 13.636 16H1.364C.61 16 0 15.39 0 14.638v-3.404a1.363 1.363 0 0 1 2.727 0v1.705a.34.34 0 0 0 .34.338Z"
                  fill="#5C3D2E"
                />
              </svg>
            </div> -->
          </div>
        </div>
      </div>
      <div class="container-content__inner">
        <div class="table-search">
          <img
            class="table-image"
            :src="shops[0] && shops[0].brand.avatar"
            :alt="shops.name"
          />
          <div class="search">
            <input
              v-model="filters.brand"
              class="search-input"
              type="text"
              :placeholder="$t('labels.searchShops')"
              @input="searchBrand"
            />
            <svg
              width="12"
              height="12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.573 6.975a4.612 4.612 0 1 0-1.597 1.597L10.403 12 12 10.403 8.573 6.975ZM6.87 5.274a2.355 2.355 0 0 1-4.612-.662 2.354 2.354 0 1 1 4.612.662Z"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
        <template v-if="activeSwitcher === 'list'">
          <SkeletonLoader
            v-if="$store.state.state.isGlobalLoading"
            type="table"
          />
          <StoresTable v-else :stores="shops" />

          <div class="pagination">
            <a
              v-if="pagination.page < pagination.total"
              @click="loadMoreShops"
              class="load-more"
              :class="{ 'load-more--disabled': isLoadingMore }"
              >{{ $t("labels.loadMoreShops") }}
              <svg v-if="isLoadingMore" class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </a>
          </div>
        </template>
        <template v-else>
          <GMapMap
            :center="{
              lat: shops[0]?.lat || 45.815399,
              lng: shops[0]?.lng || 15.966568,
            }"
            :zoom="7"
            style="width: 500px; height: 300px"
          >
            <GMapCluster :zoomOnClick="true">
              <GMapMarker
                :key="index"
                v-for="(shop, index) in shops.filter((b) => b.lat && b.lng)"
                :position="{ lat: shop.lat, lng: shop.lng }"
                :clickable="true"
                :draggable="false"
                @click="openMarker(shop.id)"
              >
                <GMapInfoWindow
                  :closeclick="true"
                  @closeclick="openMarker(null)"
                  :opened="openedMarkerID === shop.id"
                >
                  <div class="map-details">
                    <p class="title bold">{{ shop.name }}</p>
                    <p class="small">{{ shop.address }}</p>
                  </div>
                </GMapInfoWindow></GMapMarker
              >
            </GMapCluster>
          </GMapMap>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { removeFalsy } from "@/libs/helpers";
import { useHead } from "@vueuse/head";
import _ from "lodash";
import StoresTable from "@/components/tables/StoresTable";

export default {
  name: "ShopsId",
  components: {
    StoresTable,
  },
  data() {
    return {
      shops: [],
      columns: [
        {
          key: "brand",
          title: this.$t("labels.brand"),
        },
        {
          key: "address",
          title: this.$t("labels.address"),
        },
        {
          key: "redirect",
          title: "",
        },
      ],
      pagination: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      activeSwitcher: "list",
      filters: {
        brand: "",
      },
      openedMarkerID: null,
      isLoadingMore: false,
    };
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.shops")}`,
    });
  },
  created() {
    this.getStoreBrandsStores();
  },
  methods: {
    print() {
      window.print();
    },
    goToShopDetails(item) {
      this.$router.push(`/shops/${this.$route.params.id}/${item}`);
    },
    openMarker(id) {
      this.openedMarkerID = id;
    },
    getStoreBrandsStores() {
      if (this.pagination.page === 1) {
        this.$store.commit("SET_GLOBAL_LOADING", true);
      }
      this.isLoadingMore = true;
      this.$store
        .dispatch(
          "getStores",
          removeFalsy({
            params: {
              page: this.pagination.page,
              per_page: this.pagination.perPage,
              ["storeBrand|id"]: this.$route.params.id,
              search: this.filters.brand || null,
            },
          })
        )
        .then((res) => {
          setTimeout(() => {
            this.$store.commit("SET_GLOBAL_LOADING", false);
            this.isLoadingMore = false;
          }, 1500);
          let response = res.data.data;
          this.shops = this.shops.concat(response);
          this.pagination.total = res.data.meta.last_page;
        })
        .catch((err) => {
          return err;
        });
    },
    resetShops() {
      this.pagination = {
        page: 1,
        perPage: 20,
        total: 0,
      };
      this.shops = [];
    },
    searchBrand: _.debounce(function () {
      this.resetShops();
      this.getStoreBrandsStores();
    }, 1000),
    loadMoreShops() {
      if (!this.isLoadingMore) {
        this.pagination.page++;
        this.getStoreBrandsStores();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &-content {
    padding-bottom: 0;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 550px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .back {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        svg {
          display: block;
          margin-right: 10px;
        }
      }
      &--action {
        display: flex;
        align-items: center;
        @media (max-width: 550px) {
          margin-top: 20px;
          flex-direction: column;
          align-items: flex-start;
        }
        .switcher {
          margin-right: 40px;
          display: flex;
          align-items: center;
          @media (max-width: 550px) {
            margin-right: 0;
            margin-bottom: 15px;
          }
          &__inner {
            cursor: pointer;
            display: block;
            padding: 10px 15px;
            border: 1px solid $cioccolato500;
            font-weight: 600;
            color: $white;
            background-color: $wood_bark;
            font-size: 14px;
            transition: 0.3s;
            &:first-child {
              border-radius: 8px 0 0 8px;
            }
            &:last-child {
              border-radius: 0 8px 8px 0;
            }
            &.active {
              background-color: $smoke_tree;
              box-shadow: 0 5px 15px rgba(184, 92, 56, 0.3);
            }
          }
        }
        .actions {
          display: flex;
          align-items: center;
          &__inner {
            cursor: pointer;
            display: flex;
            align-items: center;
            &:first-child {
              margin-right: 30px;
            }
            a {
              font-family: Rubik;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
            .icon {
              margin-left: 10px;
            }
          }
        }
      }
    }
    &__inner {
      margin-top: 40px;
      .table-search {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 550px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .table-image {
          display: block;
          width: 100%;
          max-width: 285px;
          max-height: 50px;
          object-fit: contain;
          object-position: left;
        }
        .search {
          width: 100%;
          max-width: 355px;
          position: relative;
          @media (max-width: 550px) {
            margin-top: 20px;
          }
          &-input {
            width: 100%;
            box-sizing: border-box;
            padding: 16px 45px 16px 20px;
            background-color: $wood-bark;
            border: none;
            border-radius: 12px;
            outline: none;
            color: $white;
            font-size: 14px;
            font-family: Rubik;
            &::placeholder {
              color: $white;
            }
            @media (max-width: 550px) {
              padding: 10px 45px 10px 15px;
            }
          }
          svg {
            position: absolute;
            top: 0;
            right: 19px;
            bottom: 0;
            margin: auto;
          }
        }
      }
      .stores {
        ::v-deep {
          td,
          th {
            &:first-child {
              text-align: left;
            }
          }
        }
        .brand {
          display: flex;
          align-items: center;
          img {
            display: block;
            width: 100%;
            max-width: 30px;
            margin-right: 14px;
          }
        }
      }
      .pagination {
        margin-bottom: 37px;
        .load-more {
          box-sizing: border-box;
          cursor: pointer;
          margin-top: 40px;
          width: fit-content;
          padding: 16px 23px;
          display: flex;
          justify-content: center;
          border: 1px solid $calico;
          border-radius: 8px;
          font-size: 14px;
          min-width: 39px;
          &:not(:last-child) {
            margin-right: 16px;
          }
          @media (max-width: 500px) {
            margin-top: 20px;
            padding: 10px 12px;
            margin-right: 0 !important;
          }
          &--disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
      .vue-map-container {
        height: calc(100vh - 110px);
        min-height: 400px;
        width: calc(100% + 120px);
        margin-left: -60px;
        @media (max-width: 1440px) {
          width: calc(100% + 60px);
          margin-left: -30px;
        }
      }
    }
  }
}
</style>
