<template>
  <div>
    <div class="graph-container">
      <SkeletonLoader v-if="$store.state.state.isGlobalLoading" type="graph" />
      <template v-else>
        <template v-if="!refreshGraph">
          <Graph
            v-if="graph.series && graph.options.labels.length"
            :data="seriesLocalized"
            :chart-options="graph.options"
            :filter="graphFilter"
            @change-filter="changeFilter"
            @change-period="changePeriod"
          />
        </template>
      </template>
    </div>
    <div class="container-content">
      <div class="container-content__cards">
        <template v-if="$store.state.state.isGlobalLoading"
          ><SkeletonLoader v-for="n in 3" :key="n" type="card"
        /></template>
        <template v-else>
          <Card
            :title="$t('labels.availableBalance')"
            is-dark
            :data="authUser"
          />
          <Card
            :title="$t('labels.spent')"
            type="charge"
            is-filtered
            :data="widgetCharge"
            :active-filter="chargeFilter"
            @filter="filterCharge"
          />
          <Card
            :title="$t('labels.cashback')"
            type="cashback"
            is-filtered
            :data="widgetCashback"
            :active-filter="cashbackFilter"
            @filter="filterCashback"
          />
        </template>
      </div>
      <div class="table-header">
        <h5>{{ $t("labels.latestTransactions") }}</h5>
        <router-link to="/transactions"
          >{{ $t("labels.seeAll") }}
          <svg
            width="6"
            height="10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m1 9 4-4-4-4"
              stroke="#B85C38"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            /></svg
        ></router-link>
      </div>
      <SkeletonLoader v-if="$store.state.state.isGlobalLoading" type="table" />

      <TransactionsTable v-else :transactions="transactions" id="printableTr" />
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import { mapGetters } from "vuex";
import { formatCurrency } from "@/libs/helpers";
import Graph from "@/components/dashboard/Graph.vue";
import Card from "@/components/dashboard/Card.vue";
import TransactionsTable from "@/components/tables/TransactionsTable.vue";
import { formatDistance } from "date-fns";

export default {
  components: {
    Graph,
    Card,
    TransactionsTable,
  },
  data() {
    return {
      columns: [
        {
          key: "brand",
          title: this.$t("labels.brand"),
        },
        {
          key: "shop",
          title: this.$t("labels.shop"),
        },
        {
          key: "created_at",
          title: this.$t("labels.time"),
        },
        {
          key: "amount",
          title: this.$t("labels.amount"),
        },
        {
          key: "redirect",
          title: "",
        },
      ],
      transactions: [],
      pagination: {
        page: 1,
        perPage: 10,
      },
      graphFilter: {
        value: new Date().getFullYear(),
        type: "yearly",
      },
      graph: {
        options: {
          xaxis: {
            labels: {
              rotate: -90,
            },
          },
          chart: {
            type: "line",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: [0, 5, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              borderRadius: 16,
            },
          },
          labels: [],
          markers: {
            size: 0,
            strokeColors: "#5C3D2E",
            strokeWidth: 2,
            colors: ["#FFF"],
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return formatCurrency(y);
                }
                return y;
              },
            },
            x: {
              show: false,
            },
          },
          colors: ["rgba(224, 192, 151, 0.2)", "#E0C097"],
          grid: {
            show: false,
          },
        },
        series: [],
      },
      refreshGraph: false,
      /* widgetSaved: null, */
      widgetCharge: null,
      widgetCashback: null,
      chargeFilter: { label: "week", value: 7 },
      cashbackFilter: { label: "week", value: 7 },
    };
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.home")}`,
    });
  },
  computed: {
    ...mapGetters({
      authUser: "authUser",
    }),

    seriesLocalized() {
      return (
        this.graph.series.map((s) => ({
          ...s,
          name: this.$t(s.name),
        })) || []
      );
    },
  },
  created() {
    this.getTransactions();
    this.getGraphStats();
    /* this.getWidgetSaved(); */
    this.getWidgetCharge();
    this.getWidgetCashback();
  },
  methods: {
    goToTransaction(item) {
      this.$router.push(`/transactions/${item}`);
    },
    formatTime(date) {
      return `${formatDistance(new Date(), new Date(date))} ago`;
    },
    formatMoney(amount, type) {
      return type === "charge"
        ? `- ${formatCurrency(amount)}`
        : `+ ${formatCurrency(amount)}`;
    },
    changeFilter(value) {
      if (value === "yearly") {
        this.graphFilter = {
          value: new Date().getFullYear(),
          type: "yearly",
        };
      } else {
        this.graphFilter = {
          value: new Date().getMonth() + 1,
          type: "monthly",
        };
      }
      this.getGraphStats();
    },
    changePeriod(value) {
      this.graphFilter.value = value;
      this.getGraphStats();
    },
    getTransactions() {
      this.$store.commit("SET_GLOBAL_LOADING", true);
      this.$store
        .dispatch("getTransactions", {
          params: {
            page: this.pagination.page,
            per_page: this.pagination.perPage,
          },
        })
        .then((res) => {
          setTimeout(
            () => this.$store.commit("SET_GLOBAL_LOADING", false),
            1500
          );
          this.transactions = res.data.data;
        })
        .catch((err) => {
          return err;
        });
    },
    getGraphStats() {
      this.$store.commit("SET_GLOBAL_LOADING", true);
      this.refreshGraph = true;
      this.$store
        .dispatch("getGraphStats", {
          params: {
            filter_value: this.graphFilter.value,
            graph_type: this.graphFilter.type,
          },
        })
        .then((res) => {
          setTimeout(
            () => this.$store.commit("SET_GLOBAL_LOADING", false),
            1500
          );
          this.graph.options.labels = res.data.transactions.map(
            (obj) => obj.label
          );
          this.graph.series = [
            {
              name: "labels.spent",
              type: "column",
              data: res.data.transactions.map((obj) => obj.charge),
            },
            {
              name: "labels.cashback",
              type: "line",
              data: res.data.transactions.map((obj) => obj.cashback),
            },
          ];
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setTimeout(() => (this.refreshGraph = false), 1);
        });
    },
    /* getWidgetSaved() {
      this.$store
        .dispatch("getWidgetSaved", {
          params: {
            filter_value: "365",
          },
        })
        .then((res) => {
          this.widgetSaved = res.data;
        })
        .catch((err) => {
          return err;
        });
    }, */
    filterCharge(filter) {
      this.chargeFilter = filter;
      this.getWidgetCharge();
    },
    filterCashback(filter) {
      this.cashbackFilter = filter;
      this.getWidgetCashback();
    },
    getWidgetCharge() {
      this.$store.commit("SET_GLOBAL_LOADING", true);
      this.$store
        .dispatch("getWidgetCharge", {
          params: {
            filter_value: this.chargeFilter.value,
          },
        })
        .then((res) => {
          setTimeout(
            () => this.$store.commit("SET_GLOBAL_LOADING", false),
            1500
          );
          this.widgetCharge = res.data;
        })
        .catch((err) => {
          return err;
        });
    },
    getWidgetCashback() {
      this.$store.commit("SET_GLOBAL_LOADING", true);
      this.$store
        .dispatch("getWidgetCashback", {
          params: {
            filter_value: this.cashbackFilter.value,
          },
        })
        .then((res) => {
          setTimeout(
            () => this.$store.commit("SET_GLOBAL_LOADING", false),
            1500
          );
          this.widgetCashback = res.data;
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-content {
  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 4fr);
    grid-gap: 50px;
    margin-bottom: 80px;
    @media (max-width: 1440px) {
      grid-gap: 25px;
      margin-bottom: 40px;
      @media (max-width: 1300px) {
        grid-template-columns: repeat(2, 6fr);
        @media (max-width: 991px) {
          grid-template-columns: 12fr;
        }
      }
    }
  }
}
.graph-container {
  min-height: 400px;
}
.brand {
  display: flex;
  align-items: center;
}
.table-header {
  display: flex;
  align-items: center;
  margin-bottom: 44px;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 22px;
  }
  h5 {
    margin-right: 50px;
    @media (max-width: 500px) {
      margin-right: 0;
      font-size: 20px;
    }
  }
  a {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $wood_bark;
    font-family: Rubik;
    svg {
      margin-left: 10px;
    }
    @media (max-width: 500px) {
      margin-top: 10px;
    }
  }
}
</style>
