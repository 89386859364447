// Initial state
const state = {
  authUser: {},
};

// Getters
const getters = {
  authUser: (state) => state.authUser,
};

// Actions
const actions = {
  setAuthUser: ({ commit }, user) => {
    commit("SET_AUTH_USER", user);
  },
};

// Mutations
const mutations = {
  SET_AUTH_USER(state, user) {
    state.authUser = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
