<template>
  <div v-if="transactions.length > 0" class="trn-table">
    <div class="heading trn-table heading-prnt">
      <span class="brand remove-on-prnt">{{ $t("labels.brand") }}</span>
      <span class="shop">{{ $t("labels.shop") }}</span>
      <span class="time">{{ $t("labels.time") }}</span>
      <span class="amount">{{ $t("labels.amount") }}</span>
      <span class="actions"></span>
    </div>

    <div
      class="body trn-table heading-prnt"
      v-for="t in transactions"
      :key="t.id"
      @click="$router.push(`/transactions/${t.id}`)"
    >
      <div class="brand remove-on-prnt">
        <img
          v-if="t.store"
          :src="t.store.brand.avatar"
          :alt="t.store.brand.name"
        />
        <span v-if="t.store && t.store.brand" class="brand-txt">
          {{ t.store.brand.name }}
        </span>
        <span v-else>{{ $t("labels.deleted_store") }}</span>
      </div>

      <div class="shop">
        <template v-if="t.store">
          <span class="printable-brand-txt">{{ t.store.brand.name }}</span>
          <span class="shop-txt">{{ t.store.name }}</span>
        </template>

        <template v-else>
          <span class="printable-brand-txt">-</span>
          <span class="shop-txt">{{ $t("labels.deleted_store") }}</span>
        </template>
      </div>

      <div class="time">
        <span class="time-txt initial remove-on-prnt">{{
          formatTime(t.created_at, $i18n.locale)
        }}</span>
        <span class="time-txt readable">{{
          formatTimeReadable(t.created_at)
        }}</span>
      </div>

      <div class="amount">
        <span class="amount-txt">
          {{ formatMoney(t.amount, t.type) }}

          <span>{{ hrkToEur(t.amount, t.type) }}</span>
        </span>
      </div>

      <div class="actions">
        <svg
          class="remove-on-prnt"
          width="8"
          height="14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m1 13 6-6-6-6"
            stroke="#E0C097"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>

  <div v-else class="no-records">
    <img src="@/assets/img/no-result.png" alt="NoResult" />
    <h5>{{ $t("labels.noResults") }}</h5>
  </div>
</template>

<script>
import { formatCurrency } from "@/libs/helpers";
import { formatDistance, format, parseISO } from "date-fns";
import { enUS, hr } from "date-fns/locale";

export default {
  props: {
    transactions: {
      type: Array,
      required: true,
    },
  },

  methods: {
    formatTime(date) {
      const str = `${formatDistance(new Date(), new Date(date), {
        locale: this.$i18n.locale === "hr" ? hr : enUS,
      })}`;

      return this.$i18n.locale === "hr" ? `Prije ${str}` : `${str} ago`;
    },
    formatTimeReadable(date) {
      return format(parseISO(date), "yyyy-MM-dd");
    },
    formatMoney(amount, type) {
      return type === "charge"
        ? `- ${formatCurrency(amount)}`
        : `+ ${formatCurrency(amount)}`;
    },
    hrkToEur(amount, type) {
      return type === "charge"
        ? `- ${formatCurrency(amount / this.$exchangeRateEur, "EUR")}`
        : `+ ${formatCurrency(amount / this.$exchangeRateEur, "EUR")}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.trn-table {
  .body,
  .heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;

    span {
      font-size: 12px;
      font-weight: 400;
      color: #2d2424;
      font-family: "Rubik", sans-serif;
    }

    .actions {
      width: 50px;
      text-align: right;
    }

    .amount {
      text-align: right;
      width: 200px;
    }

    .time {
      text-align: right;
      width: 150px;
    }

    .shop {
      width: calc(100% - 700px);
    }

    .brand {
      width: 300px;
    }
  }

  .body {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .brand {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        margin-right: 15px;
      }
    }

    span {
      font-size: 14px;
    }

    .brand-txt {
      font-size: 16px;
      font-weight: 700;
    }

    .amount-txt {
      font-size: 16px;
      font-weight: 600;

      span {
        font-weight: 500;
        font-size: 12px;
        margin-top: 5px;
        display: block;
      }
    }

    .shop-txt,
    .brand-txt,
    .amount-txt,
    .time-txt {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      padding-right: 10px;
      display: block;

      &.readable {
        display: none;
      }
    }
  }

  .heading {
    margin: 0 0 25px;
    padding: 0 5px 15px;
    border-bottom: 1px solid rgba(224, 192, 151, 0.5);
  }

  .body {
    padding: 0 5px 25px;
    margin: 0 0 25px;
    border-bottom: 1px solid rgba(224, 192, 151, 0.5);

    &:last-child {
      border-bottom: none;
    }
  }

  @media only screen and (max-width: 1199px) {
    .body,
    .heading {
      .shop {
        width: calc(100% - 620px);
      }

      .brand {
        width: 220px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .body .brand-txt,
    .body .shop-txt,
    .body .time-txt {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 888px) {
    .body,
    .heading {
      .shop {
        width: calc(100% - 580px);
      }

      .brand {
        width: 180px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .heading {
      display: none;
    }

    .body {
      flex-wrap: wrap;

      .brand,
      .shop {
        width: 100%;
      }

      .shop {
        padding-left: 46px;
        margin-bottom: 10px;
      }

      .time {
        padding-left: 46px;
      }

      .time,
      .amount {
        text-align: left;
      }

      .shop-txt,
      .brand-txt,
      .time-txt {
        font-size: 14px;
      }

      .shop-txt {
        width: calc(100% - 46px);
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .actions {
      display: none;
    }

    .body .amount {
      text-align: right;
    }

    .body {
      .time {
        width: calc(50% - 46px);
      }

      .amount {
        width: 50%;
      }
    }
  }
}

.no-records {
  display: block;
  text-align: center;
  margin: 30px 0 10px;

  img {
    max-width: 255px;
    width: 100%;
  }
}

.printable-brand-txt {
  display: none !important;
}
</style>
