import axios from "axios";
// Initial state
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  // eslint-disable-next-line no-unused-vars
  getCoupons({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/cupons`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  exportCupons({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/cupons/export/${payload.mime}`, payload, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  deactivateCupon({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/cupons/${payload.id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
