import axios from "axios";
// Initial state
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  // eslint-disable-next-line no-unused-vars
  getTransactions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/transactions`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getSingleTransaction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/transactions/${payload.id}`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  exportTransactions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/transactions/export/${payload.mime}`, payload, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
