<template>
  <div class="container">
    <div class="container-content">
      <div class="container-content__header">
        <div class="container-content__header-top">
          <div class="title">
            <h5>{{ $t("labels.shops") }}</h5>
          </div>
          <div class="switcher">
            <a
              class="switcher__inner"
              :class="{ active: activeSwitcher === 'list' }"
              @click="activeSwitcher = 'list'"
              >{{ $t("labels.list") }}</a
            >
            <a
              class="switcher__inner"
              :class="{ active: activeSwitcher === 'map' }"
              @click="activeSwitcher = 'map'"
              >{{ $t("labels.map") }}</a
            >
          </div>
        </div>
        <div class="flex-box">
          <div class="filters">
            <p class="xsmall">{{ $t("labels.filter") }}</p>
            <!--<Multiselect
              class="category"
              v-model="filters.categories"
              :options="storeCategories"
              :placeholder="$t('labels.category')"
              trackBy="name"
              valueProp="id"
              mode="multiple"
              :closeOnSelect="false"
              @input="filterByCategory"
            >
              <template #multiplelabel="{ values }">
                <div class="custom-label">
                  {{ $t("labels.category") }} <span>{{ values.length }}</span>
                </div>
              </template>
              <template #option="{ option }">
                <div class="select-option">
                  <p class="xsmall">{{ option.name }}</p>
                </div>
              </template>
            </Multiselect>-->

            <DropdownFilter
              fetch-method="getStoreCategories"
              :placeholder="$t('labels.category')"
              @filterChange="(val) => setFilterValue('categories', val)"
            />

            <!--<Multiselect
              class="category"
              v-model="filters.brands"
              :options="brands"
              :placeholder="$t('labels.brand')"
              trackBy="name"
              valueProp="id"
              mode="multiple"
              :closeOnSelect="false"
              @input="filterByBrand"
            >
              <template #multiplelabel="{ values }">
                <div class="custom-label">
                  {{ $t("labels.brand") }} <span>{{ values.length }}</span>
                </div>
              </template>
              <template #option="{ option }">
                <div class="select-option">
                  <p class="xsmall">{{ option.name }}</p>
                </div>
              </template>
            </Multiselect>-->

            <!--<div
              class="shops"
              :class="{ 'shops--opened': isOpened }"
              v-click-outside="hideDropdown"
            >
              <div class="shops__search" @click="toggleShopsDropdown">
                <input
                  type="text"
                  :placeholder="storesPlaceholder"
                  @input="searchShops($event.target.value)"
                  @focus="() => toggleShopsFocus(true)"
                  @blur="() => toggleShopsFocus(false)"
                />
                <span
                  v-if="filters.storeid.length"
                  class="clear multiselect-clear-icon"
                  @click="claerSearchFilter"
                />
                <span class="caret multiselect-caret" />
              </div>
              <div v-if="isOpened" class="shops__dropdown">
                <a
                  v-for="store in stores"
                  :key="store.id"
                  class="option"
                  :class="{
                    'option--active': filters.storeid.includes(store.id),
                  }"
                  @click="filterByStore(store.id)"
                  ><img :src="store.brand.avatar" :alt="store.name" />
                  <span>{{ store.name }}</span></a
                >
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="container-content__inner">
        <div class="table-search">
          <p :style="`opacity: ${!totalBrands ? '0' : '1'}`" class="large">
            {{ totalBrands }}
            {{
              $i18n.locale === "en"
                ? $t("labels.numBrands")
                : totalBrands === 1
                ? $t("labels.numBrandsV1")
                : totalBrands === 0
                ? $t("labels.numBrandsV2")
                : $t("labels.numBrandsV3")
            }}
          </p>
          <div class="search">
            <input
              v-model="filters.brand"
              class="search-input"
              type="text"
              :placeholder="$t('labels.searchShops')"
              @input="searchBrand"
            />
            <svg
              width="12"
              height="12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.573 6.975a4.612 4.612 0 1 0-1.597 1.597L10.403 12 12 10.403 8.573 6.975ZM6.87 5.274a2.355 2.355 0 0 1-4.612-.662 2.354 2.354 0 1 1 4.612.662Z"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
        <template v-if="activeSwitcher === 'list'">
          <SkeletonLoader
            v-if="$store.state.state.isGlobalLoading"
            type="table"
          />

          <BrandsTable :brands="brands" v-else />

          <div class="pagination">
            <a
              v-if="pagination.page < pagination.total"
              @click="loadMoreBrands"
              class="load-more"
              :class="{ 'load-more--disabled': isLoadingMore }"
              >{{ $t("labels.loadMoreBrands") }}
              <svg v-if="isLoadingMore" class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </a>
          </div>
        </template>
        <template v-else>
          <GMapMap
            :center="{
              lat: brands[0]?.lat || 45.815399,
              lng: brands[0]?.lng || 15.966568,
            }"
            :zoom="7"
            style="width: 500px; height: 300px"
            v-if="brands.length > 0"
          >
            <GMapCluster :zoomOnClick="true">
              <GMapMarker
                :key="index"
                v-for="(brand, index) in brands.filter((b) => b.lat && b.lng)"
                :position="{ lat: brand.lat, lng: brand.lng }"
                :clickable="true"
                :draggable="false"
                @click="openMarker(brand.id)"
              >
                <GMapInfoWindow
                  :closeclick="true"
                  @closeclick="openMarker(null)"
                  :opened="openedMarkerID === brand.id"
                >
                  <div class="map-details">
                    <p class="title bold">{{ brand.name }}</p>
                    <p class="small">{{ brand.address }}</p>
                  </div>
                </GMapInfoWindow></GMapMarker
              >
            </GMapCluster>
          </GMapMap>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { removeFalsy } from "@/libs/helpers";
import _ from "lodash";
//import Multiselect from "@vueform/multiselect";
import { useHead } from "@vueuse/head";
import BrandsTable from "@/components/tables/BrandsTable";

export default {
  name: "Shops",
  components: {
    //Multiselect,
    BrandsTable,
  },
  data() {
    return {
      activeSwitcher: "list",
      brands: [],
      columns: [
        {
          key: "brand",
          title: this.$t("labels.brand"),
        },
        {
          key: "category",
          title: this.$t("labels.category"),
        },
        {
          key: "amount",
          title: this.$t("labels.amount"),
        },
        {
          key: "redirect",
          title: "",
        },
      ],
      stores: [],
      storeCategories: [],
      pagination: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      storesPagination: {
        page: 1,
        perPage: 10,
        total: 0,
      },
      filters: {
        brand: "",
        categories: null,
        brands: [],
        storeid: [],
        search: "",
      },
      openedMarkerID: null,
      totalBrands: null,
      isLoadingMore: false,
      isOpened: false,
      storesPlaceholder: "Shops",
      category_search: "",
    };
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.shops")}`,
    });
  },
  async created() {
    await this.getStoreCategories();
    await this.getStores();
    await this.getStoreBrands();
  },
  methods: {
    async setFilterValue(col, val) {
      this.filters[col] = val;
      await this.resetBrands();
      await this.getStoreBrands();
    },
    hideDropdown() {
      this.isOpened = false;
    },
    toggleShopsDropdown() {
      this.isOpened = !this.isOpened;
    },
    searchShops(val) {
      this.filters.search = val;
      this.getStores();
    },
    searchCategories(val) {
      this.category_search = val;
      this.getStoreCategories();
    },
    goToShops(item) {
      this.$router.push(`/shops/${item}`);
    },
    openMarker(id) {
      this.openedMarkerID = id;
    },
    getStores() {
      this.$store
        .dispatch("getStores", {
          params: {
            page: this.storesPagination.page,
            per_page: this.storesPagination.perPage,
            ["storeCategory|id"]: this.filters.categories,
            ["storeBrand|id"]: this.filters.brands,
            search: this.filters.search,
          },
        })
        .then((res) => {
          this.stores = res.data.data;
          this.storesPagination.total = res.data.meta.total;
        })
        .catch((err) => {
          return err;
        });
    },
    getStoreCategories() {
      const params = {
        search: this.category_search,
      };

      this.$store
        .dispatch("getStoreCategories", { params })
        .then((res) => {
          let filteredCategires = res.data.data.filter((obj) => {
            return obj.name !== "All";
          });
          this.storeCategories = filteredCategires;
        })
        .catch((err) => {
          return err;
        });
    },
    getStoreBrands() {
      if (this.pagination.page === 1) {
        this.$store.commit("SET_GLOBAL_LOADING", true);
      }
      this.isLoadingMore = true;
      this.$store
        .dispatch(
          "getStoreBrands",
          removeFalsy({
            params: {
              page: this.pagination.page,
              per_page: this.pagination.perPage,
              search: this.filters.brand || null,
              ["storeCategories|id"]: this.filters.categories,
              ["stores|id"]: this.filters.storeid,
            },
          })
        )
        .then((res) => {
          setTimeout(() => {
            this.$store.commit("SET_GLOBAL_LOADING", false);
            this.isLoadingMore = false;
          }, 1500);
          let response = res.data.data;
          this.brands = this.brands.concat(response);
          this.pagination.total = res.data.meta.last_page;
          this.totalBrands = res.data.meta.total;
        })
        .catch((err) => {
          return err;
        });
    },
    searchBrand: _.debounce(function () {
      this.resetBrands();
      this.getStoreBrands();
    }, 1000),
    resetBrands() {
      this.pagination = {
        page: 1,
        perPage: 20,
        total: 0,
      };
      this.brands = [];
    },
    loadMoreBrands() {
      if (!this.isLoadingMore) {
        this.pagination.page++;
        this.getStoreBrands();
      }
    },
    async claerSearchFilter() {
      this.filters.storeid = [];
      this.storesPlaceholder = "Shops";
      this.isOpened = false;
      await this.resetBrands();
      await this.getStoreBrands();
    },
    async filterByCategory(val) {
      this.filters.categories = val;
      await this.resetBrands();
      await this.getStoreBrands();
      await this.getStores();
    },
    async filterByBrand(val) {
      this.filters.brands = val;
      await this.getStores();
    },
    async filterByStore(id) {
      if (this.filters.storeid.includes(id)) {
        this.filters.storeid.splice(this.filters.storeid.indexOf(id), 1);
      } else {
        this.filters.storeid.push(id);
      }

      const names =
        this.stores
          .filter((shop) => this.filters.storeid.includes(shop.id))
          .map((shop) => shop.name || "") || [];

      this.storesPlaceholder = names.length > 0 ? names.join(", ") : "Shops";

      await this.resetBrands();
      await this.getStoreBrands();
    },

    toggleShopsFocus(isFocused) {
      if (isFocused) {
        this.storesPlaceholder = "Select or write...";
      } else {
        const names =
          this.stores
            .filter((shop) => this.filters.storeid.includes(shop.id))
            .map((shop) => shop.name || "") || [];

        this.storesPlaceholder = names.length > 0 ? names.join(", ") : "Shops";
      }
    },

    reachedEndOfList(reached) {
      console.log("R", reached);
      if (reached) {
        this.$store.dispatch("getStoreCategories").then((res) => {
          console.log("R", res);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect-clear-icon {
  background-color: #999 !important;
}
.container {
  &-content {
    padding-bottom: 0;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .switcher {
        margin-right: 40px;
        display: flex;
        align-items: center;
        @media (max-width: 1200px) {
          margin-right: 0;
        }
        &__inner {
          cursor: pointer;
          display: block;
          padding: 10px 15px;
          border: 1px solid $cioccolato500;
          font-weight: 600;
          color: $white;
          background-color: $wood_bark;
          font-size: 14px;
          transition: 0.3s;
          @media (max-width: 500px) {
            padding: 8px 10px;
            font-size: 12px;
          }
          &:first-child {
            border-radius: 8px 0 0 8px;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
          }
          &.active {
            background-color: $smoke_tree;
            box-shadow: 0 5px 15px rgba(184, 92, 56, 0.3);
          }
        }
      }
      .flex-box {
        display: flex;
        align-items: center;
        @media (max-width: 1200px) {
          margin-top: 20px;
          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        .filters {
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
          }
          .xsmall {
            margin-right: 20px;
            @media (max-width: 768px) {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
          .category {
            min-width: 130px;
            margin-right: 20px;
            @media (max-width: 768px) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
          .shops {
            min-width: 220px;
            position: relative;
            &--opened {
              .shops__search {
                border-radius: 8px 8px 0 0;
              }
              .caret {
                transform: rotate(180px);
              }
            }
            &__search {
              cursor: pointer;
              position: relative;
              display: flex;
              align-items: center;
              border: 1px solid $calico;
              border-radius: 8px;
              padding: 8px 14px;
              input {
                cursor: pointer;
                padding: 0;
                width: 100%;
                border: none;
                background: transparent;
                outline: none;
                font-size: 14px;
                line-height: 19px;
                font-family: Rubik;
                &::placeholder {
                  color: $wood_bark;
                  font-family: Rubik;
                }
              }
              .clear {
                position: absolute;
                right: 32px;
              }
              .caret {
                margin-right: 0;
                position: absolute;
                right: 14px;
              }
            }
            &__dropdown {
              position: absolute;
              top: 100%;
              right: 0;
              left: 0;
              background: $white;
              z-index: 1;
              border: 1px solid $calico;
              border-radius: 0 0 8px 8px;
              max-height: 162px;
              overflow: auto;
              border-top: none;
              .option {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 8px 12px;
                font-size: 12px;
                font-family: Rubik;
                &--active {
                  background-color: $calico;
                }
                img {
                  margin-right: 10px;
                  display: block;
                  width: 30px;
                }
              }
            }
          }
          .custom-label {
            width: fit-content;
            padding-right: 7px;
            padding-left: 15px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $wood_bark;
            font-family: Rubik;
            margin-right: auto;
            span {
              margin-left: 5px;
              min-width: 20px;
              min-height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              color: $white;
              background-color: $smoke_tree;
              border-radius: 50px;
              font-weight: 700;
              font-family: Rubik;
            }
          }
          .select-option {
            display: flex;
            align-items: center;
            img {
              margin-right: 10px;
              display: block;
              width: 30px;
            }
            p {
              font-size: 12px;
            }
          }
          .select-pagination {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            border-top: 1px solid $calico;
            background-color: $white;
            display: grid;
            grid-template-columns: 3fr 6fr 3fr;
            a {
              padding: 5px;
              font-size: 14px;
              transition: 0.3s;
              &.select-pagination {
                &__prev {
                  border-right: 1px solid $calico;
                  &.isDisabled {
                    pointer-events: none;
                    opacity: 0.5;
                  }
                  &:hover {
                    background-color: $calico;
                    color: $wood_bark;
                  }
                }
                &__counter {
                  text-align: center;
                }
                &__next {
                  border-left: 1px solid $calico;
                  &.isDisabled {
                    pointer-events: none;
                    opacity: 0.5;
                  }
                  &:hover {
                    background-color: $calico;
                    color: $wood_bark;
                  }
                }
              }
            }
          }
        }
      }
      &-top {
        display: flex;
        align-items: center;
        @media (max-width: 500px) {
          width: 100%;
          justify-content: space-between;
        }
        .title {
          margin-right: 30px;
          @media (max-width: 500px) {
            margin-right: 15px;
          }
        }
        .buttons {
          display: flex;
          &__left {
            background-color: #b85c38;
            border: 1px solid #5c3d2e;
            cursor: pointer;
            border-radius: 8px 0px 0px 8px;
            p {
              font-family: Rubik;
              font-size: 14px;
              font-weight: 600;
              line-height: 17px;
              color: $white;
              padding: 10px 15px;
            }
          }
          &__right {
            background-color: #2d2424;
            border: 1px solid #5c3d2e;
            cursor: pointer;
            border-radius: 0px 8px 8px 0px;
            p {
              font-family: Rubik;
              font-size: 14px;
              font-weight: 600;
              line-height: 17px;
              color: $white;
              padding: 10px 15px;
            }
          }
        }
      }
    }
    &__inner {
      margin-top: 60px;
      @media (max-width: 768px) {
        margin-top: 30px;
      }
      .table-search {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .large {
          font-weight: 500;
        }
        .search {
          width: 100%;
          max-width: 355px;
          position: relative;
          @media (max-width: 768px) {
            margin-top: 20px;
          }
          &-input {
            width: 100%;
            box-sizing: border-box;
            padding: 16px 45px 16px 20px;
            background-color: $wood-bark;
            border: none;
            border-radius: 12px;
            outline: none;
            color: $white;
            font-size: 14px;
            font-family: Rubik;
            &::placeholder {
              color: $white;
            }
            @media (max-width: 500px) {
              padding: 10px 45px 10px 15px;
            }
          }
          svg {
            position: absolute;
            top: 0;
            right: 19px;
            bottom: 0;
            margin: auto;
          }
        }
      }
      .brands {
        ::v-deep {
          td {
            &:first-child {
              width: 60%;
            }
            &:nth-child(2) {
              min-width: 180px;
            }
            &:nth-child(3) {
              min-width: 90px;
            }
          }
        }
        .brand {
          display: flex;
          align-items: center;
        }
        .category {
          cursor: pointer;
          position: relative;
          font-weight: 500;
          &:hover {
            .category-dropdown {
              opacity: 1;
            }
          }
          &-dropdown {
            opacity: 0;
            pointer-events: none;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 10px;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: $wood_bark;
            color: $white;
            z-index: 1;
          }
          &-text {
            display: block;
            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
        .shops {
          margin-left: auto;
          width: fit-content;
          padding: 8px 10px;
          display: block;
          font-size: 12px;
          font-family: Rubik;
          color: $wood_bark;
          background-color: rgba(224, 192, 151, 0.2);
          border-radius: 8px;
        }
      }
      .pagination {
        margin-bottom: 37px;
        .load-more {
          box-sizing: border-box;
          cursor: pointer;
          margin-top: 40px;
          width: fit-content;
          padding: 16px 23px;
          display: flex;
          justify-content: center;
          border: 1px solid $calico;
          border-radius: 8px;
          font-size: 14px;
          min-width: 39px;
          &:not(:last-child) {
            margin-right: 16px;
          }
          @media (max-width: 500px) {
            margin-top: 20px;
            padding: 10px 12px;
            margin-right: 0 !important;
          }
          &--disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
      .vue-map-container {
        height: calc(100vh - 130px);
        min-height: 400px;
        width: calc(100% + 120px);
        margin-left: -60px;
        @media (max-width: 1440px) {
          width: calc(100% + 60px);
          margin-left: -30px;
        }
      }
    }
  }
}

.shops__search input {
  max-width: 80% !important;
  text-overflow: ellipsis;

  ::placeholder {
    text-overflow: ellipsis;
  }
}
</style>
