import { createStore } from "vuex";

import user from "./modules/user";
import dashboard from "./modules/dashboard";
import transactions from "./modules/transactions";
import stores from "./modules/stores";
import state from "./modules/state";
import coupons from "./modules/coupons";

export default createStore({
  modules: {
    user,
    dashboard,
    transactions,
    stores,
    state,
    coupons,
  },
});
