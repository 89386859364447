<template>
  <div class="container">
    <div class="container__inner">
      <div
        class="container__inner-card"
        :class="{ 'container__inner-card--dark': isDark }"
      >
        <div class="card">
          <div class="card__header">
            <p class="title">{{ title }}</p>
            <div
              v-if="isFiltered"
              class="dropdown"
              @blur="isOpened = false"
              tabindex="0"
            >
              <div class="dropdown__inner" @click="toggleDropdown">
                <p>
                  {{
                    $t(period.find((p) => p.label === activeFilter.label).title)
                  }}
                </p>
                <svg
                  width="8"
                  height="6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4 6 .536 0h6.928L4 6Z" fill="#2D2424" />
                </svg>
              </div>
              <div v-if="isOpened" class="dropdown--opened">
                <ul>
                  <li v-for="item in period" :key="item.value">
                    <a
                      @click="filterByPeriod(item)"
                      :class="{ active: item.label === activeFilter.label }"
                      >{{ $t(item.title) }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="data" class="balance">
            <div>
              <h4 class="amount">
                {{
                  formatMoney(!type ? data.balance : data.transactions_summary)
                }}
              </h4>
              <span class="eur">
                {{ hrkToEur(!type ? data.balance : data.transactions_summary) }}
              </span>
              <div :class="`flex__box ${type}`">
                <template v-if="type === 'charge'">
                  <p class="percent">
                    <svg
                      width="11"
                      height="9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.5 9 .737.75h9.526L5.5 9Z" fill="#C72929" />
                    </svg>
                    {{ data.balance_difference_percentage.toFixed(2) + "%" }}
                  </p>
                  <p class="time">
                    {{
                      $t(
                        period.find((p) => p.label === activeFilter.label).title
                      )
                    }}
                  </p>
                </template>
                <template v-else-if="type === 'cashback'">
                  <p class="percent">
                    <svg
                      width="11"
                      height="9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m5.5 0 4.763 8.25H.737L5.5 0Z" fill="#0D8920" />
                    </svg>
                    {{ data.balance_difference_percentage.toFixed(2) + "%" }}
                  </p>
                  <p class="time">
                    {{
                      $t(
                        period.find((p) => p.label === activeFilter.label).title
                      )
                    }}
                  </p>
                </template>
              </div>
            </div>
            <svg
              v-if="type === 'cashback'"
              width="86"
              height="67"
              viewBox="0 0 86 67"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="radial"
            >
              <path
                d="M2 65C2.7193 53.1667 14.8354 19.3817 31.4912 34C52 52 50.3286 23.2205 59.5 16.5C69.2065 9.38742 77 19 84 1.5"
                stroke="#0D8920"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <svg
              v-if="type === 'charge'"
              width="86"
              height="67"
              viewBox="0 0 86 67"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="radial"
            >
              <path
                d="M84 65C83.2807 53.1667 76.3754 30.4 54.5088 34C32.6421 37.6 10.3918 13.8333 2 1.5"
                stroke="#C72929"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@/libs/helpers";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isDark: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFiltered: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    activeFilter: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      isOpened: false,
      period: [
        { label: "week", value: "7", title: "labels.this_week" },
        { label: "month", value: "30", title: "labels.this_month" },
        { label: "year", value: "365", title: "labels.this_year" },
      ],
    };
  },
  methods: {
    formatMoney(amount) {
      return formatCurrency(amount);
    },
    hrkToEur(amount) {
      return formatCurrency(amount / this.$exchangeRateEur, "EUR");
    },
    toggleDropdown() {
      this.isOpened = !this.isOpened;
    },
    filterByPeriod(period) {
      this.isOpened = false;
      this.$emit("filter", period);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &__inner {
    height: 100%;
    &-card {
      border-radius: 12px;
      box-shadow: 0px 5px 20px 0px #00000040;
      height: 100%;
      &--dark {
        background: linear-gradient(18.44deg, #2d2424 78.01%, #5c3d2e 88.52%);
        .eur,
        .amount,
        .percent,
        .title {
          color: $white;
        }
        .time {
          color: $calico !important;
        }
      }
      .card {
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        @media (max-width: 500px) {
          padding: 25px 20px;
        }
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 40px;
          min-height: 39px;
          @media (max-width: 500px) {
            margin-bottom: 20px;
            flex-direction: column;
            align-items: flex-start;
          }
          .dropdown {
            position: relative;
            @media (max-width: 500px) {
              margin-top: 10px;
            }
            &__inner {
              cursor: pointer;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 10px 15px;
              border: 1px solid rgba(45, 36, 36, 0.1);
              border-radius: 8px;
              p {
                font-size: 14px;
                line-height: 17px;
              }
              svg {
                display: block;
                margin-left: 5px;
              }
            }
            &--opened {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              box-sizing: border-box;
              padding: 10px 5px;
              border: 1px solid rgba(45, 36, 36, 0.1);
              box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
              background-color: $white;
              z-index: 1;
              ul {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                  &:not(:last-child) {
                    margin-bottom: 5px;
                  }
                  a {
                    white-space: nowrap;
                    cursor: pointer;
                    display: block;
                    padding: 5px 10px;
                    border-radius: 8px;
                    font-size: 14px;
                    line-height: 17px;
                    font-family: Rubik;
                    transition: 0.3s;
                    &:hover {
                      background: rgba(224, 192, 151, 0.2);
                    }
                    &.active {
                      background: rgba(224, 192, 151, 0.2);
                    }
                  }
                }
              }
            }
          }
          .title {
            font-family: Rubik;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
          }
        }
        .balance {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .radial {
            margin-left: 25px;
          }
        }
        .eur,
        .amount {
          font-family: Rubik;
          font-size: 30px;
          font-weight: 700;
          line-height: 36px;
          margin-bottom: 10px;
          @media (max-width: 500px) {
            font-size: 24px;
          }
        }
        .eur {
          font-size: 18px;
          margin: -10px 0 10px;
          display: block;
        }
        .flex__box {
          display: flex;
          align-items: center;
          @media (max-width: 500px) {
            flex-direction: column;
          }
          &.charge {
            .percent {
              color: $red;
            }
          }
          &.cashback {
            .percent {
              color: $green;
            }
          }
        }
        .percent {
          margin-left: 5px;
          font-family: Rubik;
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
        }
        .time {
          font-family: Rubik;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          margin-left: 5px;
          color: $cioccolato500;
        }
      }
    }
  }
}
</style>
