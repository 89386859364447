<template>
  <div class="container">
    <div class="container-content">
      <div class="container-content__header">
        <div class="container-content__header-top">
          <div class="title">
            <h5>{{ $t("labels.coupons") }}</h5>
          </div>
          <div class="switcher">
            <a
              class="switcher__inner"
              :class="{ active: activeStatus === 'active' }"
              @click="changeStatus('active')"
              >{{ $t("labels.active") }}</a
            >
            <a
              class="switcher__inner"
              :class="{ active: activeStatus === 'used' }"
              @click="changeStatus('used')"
              >{{ $t("labels.used") }}</a
            >
            <a
              class="switcher__inner"
              :class="{ active: activeStatus === 'canceled' }"
              @click="changeStatus('canceled')"
              >{{ $t("labels.canceled") }}</a
            >
          </div>
        </div>
        <div class="flex-box filters-flex-box">
          <div class="filters">
            <p class="xsmall">{{ $t("labels.filter") }}</p>
            <date-picker
              :lang="$i18n.locale"
              class="filter-elem date-pckr"
              v-model="filters.period"
              :placeholder="$t('labels.period')"
              range
              valueType="format"
              @clear="
                (clear) => {
                  filters.period = null;
                }
              "
              @input="filterDate"
            ></date-picker>

            <DropdownFilter
              class="filter-elem"
              v-if="showShopsFilters"
              fetch-method="getStores"
              :placeholder="$t('labels.shops')"
              @filterChange="(val) => setFilterValue('shopId', val)"
              image-cont="brand.avatar"
            />

            <!--<div
              v-if="showShopsFilters"
              class="shops"
              :class="{ 'shops--opened': isOpened }"
              v-click-outside="hideDropdown"
            >
              <div class="shops__search" @click="toggleShopsDropdown">
                <input
                  type="text"
                  :placeholder="shopsPlaceholder"
                  @input="searchShops($event.target.value)"
                  @focus="() => toggleShopsFocus(true)"
                  @blur="() => toggleShopsFocus(false)"
                />
                <span
                  v-if="filters.shopId.length"
                  class="clear multiselect-clear-icon"
                  @click="claerSearchFilter"
                />
                <span class="caret multiselect-caret" />
              </div>
              <div v-if="isOpened" class="shops__dropdown">
                <a
                  v-for="shop in shops"
                  :key="shop.id"
                  class="option"
                  :class="{
                    'option--active': filters.shopId.includes(shop.id),
                  }"
                  @click="filterByShops(shop.id)"
                  ><img :src="shop.brand.avatar" :alt="shop.name" />
                  <span>{{ shop.name }}</span></a
                >
              </div>
            </div>-->
          </div>
          <div
            class="flex-box__inner"
            :class="{
              'is-disabled':
                coupons.length <= 0 || $store.state.state.isGlobalLoading,
            }"
          >
            <!--<div class="print-box" @click="print">
              <a>{{ $t("labels.print") }}</a>
              <svg
                class="icon"
                width="18"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.37 2.933h-2.974L12.619.52a.773.773 0 0 0-.72-.52H6.082a.749.749 0 0 0-.72.52l-.777 2.413H1.63C.72 2.933 0 3.657 0 4.529v5.309c0 .89.739 1.596 1.63 1.596h.265l-1.156 3.62a.672.672 0 0 0 .114.649c.132.186.36.297.606.297h15.082a.768.768 0 0 0 .606-.297.725.725 0 0 0 .114-.65l-1.156-3.6h.265c.91 0 1.63-.724 1.63-1.597V4.53c0-.891-.739-1.596-1.63-1.596Zm-1.155 1.874a.86.86 0 0 1 .871.854.86.86 0 0 1-.871.854.873.873 0 0 1-.872-.854c0-.464.398-.854.872-.854ZM6.65 1.467h4.717l.474 1.447H6.177l.474-1.448ZM2.482 14.533 4.377 8.63h9.246l1.895 5.903H2.482Z"
                  fill="#5C3D2E"
                />
                <path
                  d="M6.234 10.988h5.513c.341 0 .607-.26.607-.594a.593.593 0 0 0-.607-.594H6.234a.593.593 0 0 0-.607.594c0 .335.284.594.607.594ZM12.543 12.158H5.457a.593.593 0 0 0-.606.594c0 .334.265.594.606.594h7.086c.341 0 .607-.26.607-.594a.604.604 0 0 0-.607-.594Z"
                  fill="#5C3D2E"
                />
              </svg>
            </div>-->
            <div class="export-box" @click="exportCoupons">
              <a>{{ $t("labels.export") }}</a>
              <svg
                class="icon"
                width="15"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="m8.326.344 3.183 3.121c1.281 1.284-.64 3.21-1.921 1.926l-.013-.013c-.394-.4-.716-.268-.716.297v2.498c0 .75-.612 1.359-1.367 1.359h.016a1.362 1.362 0 0 1-1.367-1.36V5.676c0-.564-.32-.698-.716-.297l-.013.013C4.132 6.675 2.21 4.75 3.491 3.465L6.63.35A1.21 1.21 0 0 1 8.325.344ZM3.067 13.277h8.866c.19 0 .34-.151.34-.338v-1.705a1.363 1.363 0 0 1 2.727 0v3.404C15 15.39 14.39 16 13.636 16H1.364C.61 16 0 15.39 0 14.638v-3.404a1.363 1.363 0 0 1 2.727 0v1.705a.34.34 0 0 0 .34.338Z"
                  fill="#5C3D2E"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="container-content__inner">
        <SkeletonLoader
          v-if="$store.state.state.isGlobalLoading"
          type="table"
        />

        <CuponsTable
          v-else
          :coupons="coupons"
          @deactivateCoupon="deactivateCoupon"
          id="printable"
        />

        <!--<Table
          v-if="!$store.state.state.isGlobalLoading"
          class="coupons"
          :columns="columns"
          :data="coupons"
        >
          <template #id="{ item }">
            <p class="semi-bold">{{ item.name }}</p>
          </template>
          <template #time="{ item }">
            <p class="small">{{ formatTime(item.created_at) }}</p>
          </template>
          <template #amount="{ item }">
            <p class="amount semi-bold">{{ formatMoney(item.amount) }}</p>
          </template>
          <template #action="{ item }">
            <a
              class="status"
              :class="{
                'status--active': item.status === 'active',
                'status--used': item.status === 'used',
                'status--canceled': item.status === 'canceled',
              }"
              @click="deactivateCoupon(item.status, item.id)"
              >{{ item.status === "active" ? "Deactivate" : item.status }}</a
            >
          </template>
        </Table>-->
        <div class="pagination">
          <a
            v-if="pagination.page < pagination.total"
            @click="loadMoreCoupons"
            class="load-more"
            :class="{ 'load-more--disabled': isLoadingMore }"
            >{{ $t("labels.loadMoreCupons") }}
            <svg v-if="isLoadingMore" class="spinner" viewBox="0 0 50 50">
              <circle
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/hr";
import "vue2-datepicker/locale/en";
import { formatCurrency, removeFalsy } from "@/libs/helpers";
import { formatDistance } from "date-fns";
import { useHead } from "@vueuse/head";
import CuponsTable from "@/components/tables/CuponsTable";

import Swal from "sweetalert2";
export default {
  name: "Coupons",
  components: {
    DatePicker,
    CuponsTable,
  },
  data() {
    return {
      showShopsFilters: false,
      filters: {
        period: "",
        search: "",
        shopId: [],
        created_at: null,
      },
      activeStatus: "active",
      shops: [],
      isOpened: false,
      columns: [
        {
          key: "id",
          title: "ID",
        },
        {
          key: "time",
          title: this.$t("labels.time"),
        },
        {
          key: "amount",
          title: this.$t("labels.amount"),
        },
        {
          key: "action",
          title: this.$t("labels.action"),
        },
      ],
      coupons: [],
      pagination: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      shopsPagination: {
        page: 1,
        perPage: 10,
        total: 0,
      },
      isLoadingMore: false,
      shopsPlaceholder: "Shops",
    };
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.cupons")}`,
    });
  },
  mounted() {
    this.getShops();
    this.getCoupons();
  },
  methods: {
    async setFilterValue(col, val) {
      this.filters[col] = val;
      await this.resetShops();
      await this.getCoupons();
    },
    hideDropdown() {
      this.isOpened = false;
    },
    print() {
      this.$htmlToPaper("printable");
      //window.print();
    },
    searchShops(val) {
      this.filters.search = val;
      this.getShops();
    },
    exportCoupons() {
      this.$store
        .dispatch(
          "exportCupons",
          removeFalsy({
            mime: "csv",
            params: {
              page: this.pagination.page,
              per_page: this.pagination.perPage,
              ["transaction|store|id"]: this.filters.shopId,
              created_at: this.filters.created_at,
              status: this.activeStatus,
            },
          })
        )
        .then((res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "cupon.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((err) => {
          return err;
        });
    },
    formatTime(date) {
      return `${formatDistance(new Date(), new Date(date))} ago`;
    },
    formatMoney(amount) {
      return `+ ${formatCurrency(amount)}`;
    },
    searchShop(val) {
      this.filters.search = val;
      this.getShops();
    },
    async claerSearchFilter() {
      this.filters.shopId = [];
      this.shopsPlaceholder = "Shops";
      this.isOpened = false;
      await this.resetShops();
      await this.getCoupons();
    },
    loadMoreCoupons() {
      if (!this.isLoadingMore) {
        this.pagination.page++;
        this.getCoupons();
      }
    },
    getCoupons() {
      if (this.pagination.page === 1) {
        this.$store.commit("SET_GLOBAL_LOADING", true);
      }
      this.isLoadingMore = true;
      this.$store
        .dispatch(
          "getCoupons",
          removeFalsy({
            params: {
              page: this.pagination.page,
              per_page: this.pagination.perPage,
              ["transaction|store|id"]: this.filters.shopId,
              created_at: this.filters.created_at,
              status: this.activeStatus,
            },
          })
        )
        .then((res) => {
          setTimeout(() => {
            this.$store.commit("SET_GLOBAL_LOADING", false);
            this.isLoadingMore = false;
          }, 1500);
          // let response = res.data.data;
          this.pagination.total = res.data.meta.last_page;
          this.coupons = res.data.data;
          //this.coupons = this.coupons.concat(response);
        })
        .catch((err) => {
          return err;
        });
    },
    clearFilters() {
      this.filters = {
        period: "",
        search: "",
        shopId: [],
        created_at: null,
      };
    },
    async changeStatus(status) {
      const prevStatus = this.activeStatus;
      this.activeStatus = status;
      this.showShopsFilters = status === "used";

      if (prevStatus !== this.activeStatus) {
        this.claerSearchFilter();
      }

      await this.clearFilters();
      await this.resetShops();
      await this.getCoupons();
    },
    getShops() {
      this.$store
        .dispatch(
          "getStores",
          removeFalsy({
            params: {
              page: this.shopsPagination.page,
              per_page: this.shopsPagination.perPage,
              search: this.filters.search,
            },
          })
        )
        .then((res) => {
          this.shops = res.data.data;
          this.shopsPagination.total = res.data.meta.total;
        })
        .catch((err) => {
          return err;
        });
    },
    resetShops() {
      this.pagination = {
        page: 1,
        perPage: 20,
        total: 0,
      };
      this.coupons = [];
    },
    toggleShopsDropdown() {
      this.isOpened = !this.isOpened;
    },
    async filterByShops(id) {
      if (this.filters.shopId.includes(id)) {
        this.filters.shopId.splice(this.filters.shopId.indexOf(id), 1);
      } else {
        this.filters.shopId.push(id);
      }

      const names =
        this.shops
          .filter((shop) => this.filters.shopId.includes(shop.id))
          .map((shop) => shop.name || "") || [];

      this.shopsPlaceholder = names.length === 0 ? "Shops" : names.join(", ");

      await this.resetShops();
      await this.getCoupons();
    },
    async filterDate(val) {
      if (val[0] === null && val[1] === null) {
        this.filters.created_at = null;
      } else {
        this.filters.created_at = [
          {
            start: val[0],
          },
          {
            end: val[1],
          },
        ];
      }
      await this.resetShops();
      await this.getCoupons();
    },
    deactivateCoupon({ status, id }) {
      if (status.toLowerCase() === "active") {
        Swal.fire({
          showCancelButton: true,
          reverseButtons: true,
          title: this.$t("labels.cupon_delete_title"),
          text: this.$t("labels.cupon_delete_content"),
          icon: "error",
          cancelButtonText: this.$t("labels.no"),
          confirmButtonText: this.$t("labels.yes"),
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("deactivateCupon", {
                id,
              })
              .then(() => {
                this.resetShops();
                this.getCoupons();
              })
              .catch((err) => {
                return err;
              });
          }
        });
      }
    },

    toggleShopsFocus(isFocused) {
      if (isFocused) {
        this.shopsPlaceholder = "Select or write...";
      } else {
        const names =
          this.shops
            .filter((shop) => this.filters.shopId.includes(shop.id))
            .map((shop) => shop.name || "") || [];

        this.shopsPlaceholder = names.length === 0 ? "Shops" : names.join(", ");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &-content {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .switcher {
        margin-right: 40px;
        display: flex;
        align-items: center;
        @media (max-width: 1200px) {
          margin-right: 0;
        }
        &__inner {
          cursor: pointer;
          display: block;
          padding: 10px 15px;
          border: 1px solid $cioccolato500;
          font-weight: 600;
          color: $white;
          background-color: $wood_bark;
          font-size: 14px;
          transition: 0.3s;
          @media (max-width: 500px) {
            padding: 8px 10px;
            font-size: 12px;
          }
          &:first-child {
            border-radius: 8px 0 0 8px;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
          }
          &.active {
            background-color: $smoke_tree;
            box-shadow: 0 5px 15px rgba(184, 92, 56, 0.3);
          }
        }
      }
      .flex-box {
        display: flex;
        align-items: center;
        @media (max-width: 1200px) {
          // margin-top: 20px;
          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        .filters {
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
          }
          .xsmall {
            margin-right: 20px;
            @media (max-width: 768px) {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
          .category {
            min-width: 130px;
            margin-right: 20px;
            @media (max-width: 768px) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
          .shops {
            margin-left: 20px;
            min-width: 220px;
            position: relative;
            &--opened {
              .shops__search {
                border-radius: 8px 8px 0 0;
              }
              .caret {
                transform: rotate(180px);
              }
            }
            &__search {
              cursor: pointer;
              position: relative;
              display: flex;
              align-items: center;
              border: 1px solid $calico;
              border-radius: 8px;
              padding: 8px 14px;
              input {
                cursor: pointer;
                padding: 0;
                width: 100%;
                border: none;
                background: transparent;
                outline: none;
                font-size: 14px;
                line-height: 19px;
                font-family: Rubik;
                &::placeholder {
                  color: $wood_bark;
                  font-family: Rubik;
                }
              }
              .clear {
                position: absolute;
                right: 32px;
              }
              .caret {
                margin-right: 0;
                position: absolute;
                right: 14px;
              }
            }
            &__dropdown {
              position: absolute;
              top: 100%;
              right: 0;
              left: 0;
              background: $white;
              z-index: 1;
              border: 1px solid $calico;
              border-radius: 0 0 8px 8px;
              max-height: 162px;
              overflow: auto;
              border-top: none;
              .option {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 8px 12px;
                font-size: 12px;
                font-family: Rubik;
                &--active {
                  background-color: $calico;
                }
                img {
                  margin-right: 10px;
                  display: block;
                  width: 30px;
                }
              }
            }
          }
          .custom-label {
            width: fit-content;
            padding-right: 7px;
            padding-left: 15px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $wood_bark;
            font-family: Rubik;
            margin-right: auto;
            span {
              margin-left: 5px;
              min-width: 20px;
              min-height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              color: $white;
              background-color: $smoke_tree;
              border-radius: 50px;
              font-weight: 700;
              font-family: Rubik;
            }
          }
          .select-option {
            display: flex;
            align-items: center;
            img {
              margin-right: 10px;
              display: block;
              width: 30px;
            }
            p {
              font-size: 12px;
            }
          }
          .select-pagination {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            border-top: 1px solid $calico;
            background-color: $white;
            display: grid;
            grid-template-columns: 3fr 6fr 3fr;
            a {
              padding: 5px;
              font-size: 14px;
              transition: 0.3s;
              &.select-pagination {
                &__prev {
                  border-right: 1px solid $calico;
                  &.isDisabled {
                    pointer-events: none;
                    opacity: 0.5;
                  }
                  &:hover {
                    background-color: $calico;
                    color: $wood_bark;
                  }
                }
                &__counter {
                  text-align: center;
                }
                &__next {
                  border-left: 1px solid $calico;
                  &.isDisabled {
                    pointer-events: none;
                    opacity: 0.5;
                  }
                  &:hover {
                    background-color: $calico;
                    color: $wood_bark;
                  }
                }
              }
            }
          }
        }
        &__inner {
          margin-left: 40px;
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            margin-left: 0;
            margin-top: 20px;
          }
          .print-box {
            cursor: pointer;
            align-items: center;
            display: flex;
            a {
              font-family: Rubik;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
            .icon {
              margin-left: 10px;
            }
          }
          .export-box {
            cursor: pointer;
            margin-left: 30px;
            align-items: center;
            display: flex;
            a {
              font-family: Rubik;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
            .icon {
              margin-left: 10px;
            }
          }
        }
      }
      &-top {
        display: flex;
        align-items: center;
        @media (max-width: 500px) {
          width: 100%;
          justify-content: space-between;
        }
        .title {
          margin-right: 30px;
          @media (max-width: 500px) {
            margin-right: 15px;
          }
        }
        .buttons {
          display: flex;
          &__left {
            background-color: #b85c38;
            border: 1px solid #5c3d2e;
            cursor: pointer;
            border-radius: 8px 0px 0px 8px;
            p {
              font-family: Rubik;
              font-size: 14px;
              font-weight: 600;
              line-height: 17px;
              color: $white;
              padding: 10px 15px;
            }
          }
          &__right {
            background-color: #2d2424;
            border: 1px solid #5c3d2e;
            cursor: pointer;
            border-radius: 0px 8px 8px 0px;
            p {
              font-family: Rubik;
              font-size: 14px;
              font-weight: 600;
              line-height: 17px;
              color: $white;
              padding: 10px 15px;
            }
          }
        }
      }
    }
    &__inner {
      margin-top: 35px;
      ::v-deep .coupons {
        tbody {
          td {
            &:first-child {
              width: 40% !important;
            }
            &:not(:first-child) {
              width: 20%;
              white-space: nowrap;
            }
            .amount {
              color: $green;
            }
            .status {
              display: block;
              width: fit-content;
              margin-left: auto;
              text-transform: capitalize;
              padding: 8px 20px;
              font-size: 12px;
              font-family: Rubik;
              border-radius: 8px;
              &--canceled {
                color: $white;
                background-color: $red;
              }
              &--used {
                color: $white;
                background-color: $green;
              }
              &--active {
                cursor: pointer;
                color: $wood_bark;
                background-color: rgba(224, 192, 151, 0.2);
                transition: 0.3s;
                &:hover {
                  color: $white;
                  background-color: $red;
                }
              }
            }
          }
        }
      }
      .pagination {
        margin-bottom: 37px;
        .load-more {
          box-sizing: border-box;
          cursor: pointer;
          margin-top: 40px;
          width: fit-content;
          padding: 16px 23px;
          display: flex;
          justify-content: center;
          border: 1px solid $calico;
          border-radius: 8px;
          font-size: 14px;
          min-width: 39px;
          &:not(:last-child) {
            margin-right: 16px;
          }
          @media (max-width: 500px) {
            margin-top: 20px;
            padding: 10px 12px;
            margin-right: 0 !important;
          }
          &--disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.shops__search input {
  max-width: 80% !important;
  text-overflow: ellipsis;

  ::placeholder {
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 1300px) {
  .container-content__header {
    flex-wrap: wrap;
  }

  .filters-flex-box {
    width: 100% !important;
  }

  .flex-box__inner {
    margin-left: auto !important;
  }

  .container-content__header-top {
    width: 100%;
    margin-bottom: 15px;

    .switcher {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .flex-box.filters-flex-box {
    .xsmall {
      width: 100%;
    }

    .filters {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .filter-elem {
      width: calc(100% / 2 - 5px);
      margin-left: 0 !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .flex-box.filters-flex-box {
    .filter-elem {
      width: 100%;
      margin-top: 15px;

      &.date-pckr {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 415px) {
  .container-content__header-top {
    flex-wrap: wrap;

    .title {
      width: 100%;
    }

    .switcher {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

.is-disabled {
  opacity: 0.55;
  pointer-events: none !important;
}
</style>
