<template>
  <div v-if="stores.length > 0" class="brand-table">
    <div class="heading">
      <span class="brand">{{ $t("labels.name") }}</span>
      <span class="category">{{ $t("labels.address") }}</span>
      <span class="actions"></span>
    </div>

    <div
      class="body"
      v-for="s in stores"
      :key="s.id"
      @click="$router.push(`/shops/${$route.params.id}/${s.id}`)"
    >
      <div class="brand">
        <img :src="s.brand.avatar" :alt="s.name" />
        <span class="brand-txt">{{ s.name }}</span>
      </div>

      <div class="category">
        <span class="category-txt">{{ s.address }}</span>
      </div>

      <div class="actions">
        <svg
          class="remove-on-prnt"
          width="8"
          height="14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m1 13 6-6-6-6"
            stroke="#E0C097"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>

  <div v-else class="no-records">
    <img src="@/assets/img/no-result.png" alt="NoResult" />
    <h5>{{ $t("labels.noResults") }}</h5>
  </div>
</template>

<script>
export default {
  props: {
    stores: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-table {
  .body,
  .heading {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      font-size: 12px;
      font-weight: 400;
      color: #2d2424;
      font-family: "Rubik", sans-serif;
    }

    .actions {
      width: 50px;
      text-align: right;
    }

    .category {
      text-align: right;
      width: 350px;
    }

    .brand {
      width: calc(100% - 400px);
    }
  }

  .body {
    cursor: pointer;
    padding: 10px 5px;
    align-items: center;

    .brand {
      display: flex;
      align-items: center;

      img {
        width: 35px;
        margin-right: 15px;
      }
    }

    span {
      font-size: 14px;
    }

    .brand-txt {
      font-size: 16px;
      font-weight: 700;
    }

    .amount-txt {
      font-size: 16px;
      font-weight: 600;
      color: #0d8920;
    }

    .category-txt,
    .brand-txt,
    .amount-txt,
    .time-txt {
      width: 100%;
      padding-right: 10px;
      display: block;

      &.readable {
        display: none;
      }
    }

    .shops {
      margin-left: auto;
      width: fit-content;
      padding: 8px 10px;
      display: block;
      font-size: 12px;
      font-family: Rubik;
      color: $wood_bark;
      background-color: rgba(224, 192, 151, 0.2);
      border-radius: 8px;
    }
  }

  .heading {
    margin: 0 0 25px;
    padding: 0 5px 15px;
    border-bottom: 1px solid rgba(224, 192, 151, 0.5);
  }

  .body {
    padding: 0 5px 25px;
    margin: 0 0 25px;
    border-bottom: 1px solid rgba(224, 192, 151, 0.5);

    &:last-child {
      border-bottom: none;
    }
  }

  @media only screen and (max-width: 767px) {
    .heading {
      display: none;
    }

    .body {
      flex-wrap: wrap;

      .brand {
        width: 100%;
        margin: 0 0 5px;
      }

      .category {
        text-align: left;
        width: calc(100% - 100px);
        padding-left: 50px;
      }

      .actions {
        text-align: center;
      }
    }
  }
}

.no-records {
  display: block;
  text-align: center;
  margin: 30px 0 10px;

  img {
    max-width: 255px;
    width: 100%;
  }
}
</style>
