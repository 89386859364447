export const formatCurrency = (value, currency = "HRK") => {
  let formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: currency,
  });
  return formatter.format(value);
};

export const removeFalsy = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (Array.isArray(obj[prop])) {
      if (obj[prop].length > 0) {
        newObj[prop] = obj[prop];
      }
    } else if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};
