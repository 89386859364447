<template>
  <div class="container">
    <div class="filter">
      <div class="filter_switch">
        <div
          class="switch"
          :class="{ 'switch--active': filter.type === 'yearly' }"
          @click="$emit('changeFilter', 'yearly')"
        >
          {{ $t("labels.year") }}
        </div>
        <div
          class="switch"
          :class="{ 'switch--active': filter.type === 'monthly' }"
          @click="$emit('changeFilter', 'monthly')"
        >
          {{ $t("labels.month") }}
        </div>
      </div>
      <div class="filter_dropdown">
        <div class="filter_dropdown--selected" @click="isOpened = !isOpened">
          {{
            filter.type === "yearly"
              ? filter.value
              : months[filter.value - 1].label
          }}
          <svg
            width="8"
            height="6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 6 .536 0h6.928L4 6Z" fill="#2D2424" />
          </svg>
        </div>
        <transition name="fade">
          <div v-if="isOpened" class="filter_dropdown--options">
            <template v-if="filter.type === 'yearly'">
              <a
                class="option"
                v-for="year in years"
                :key="year"
                :class="{ 'option--active': filter.value === year }"
                @click="$emit('changePeriod', year)"
                >{{ year }}</a
              >
            </template>
            <a
              v-else
              class="option"
              v-for="date in months"
              :key="date.value"
              :class="{ 'option--active': filter.value === date.value }"
              @click="$emit('changePeriod', date.value)"
              >{{ date.label }}</a
            >
          </div>
        </transition>
      </div>
    </div>
    <apexchart
      type="line"
      height="385"
      :options="chartOptions"
      :series="data"
    ></apexchart>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    years() {
      let max = new Date().getFullYear();
      let min = max - 5;
      let yearList = [];
      for (let i = min; i <= max; i++) {
        yearList.push(i);
      }
      return yearList.reverse();
    },

    months() {
      return [
        { label: this.$t("months.JAN"), value: 1 },
        { label: this.$t("months.FEB"), value: 2 },
        { label: this.$t("months.MAR"), value: 3 },
        { label: this.$t("months.APR"), value: 4 },
        { label: this.$t("months.MAY"), value: 5 },
        { label: this.$t("months.JUN"), value: 6 },
        { label: this.$t("months.JUL"), value: 7 },
        { label: this.$t("months.AUG"), value: 8 },
        { label: this.$t("months.SEP"), value: 9 },
        { label: this.$t("months.OCT"), value: 10 },
        { label: this.$t("months.NOV"), value: 11 },
        { label: this.$t("months.DEC"), value: 12 },
      ];
    },
  },
};
</script>

<style>
@media only screen and (max-width: 502px) {
  .apexcharts-text.apexcharts-xaxis-label {
    font-size: 9px;
  }
}
</style>

<style lang="scss" scoped>
.container {
  position: relative;
  background: rgba(224, 192, 151, 0.2);
  .filter {
    display: flex;
    align-items: center;
    position: absolute;
    top: 40px;
    right: 60px;
    z-index: 1;
    @media only screen and (max-width: 768px) {
      top: 10px;
      right: 10px;
      flex-direction: column;
      align-items: flex-end;
    }
    &_switch {
      margin-right: 20px;
      overflow: hidden;
      display: flex;
      align-items: center;
      background: $white;
      border-radius: 8px;
      font-size: 14px;
      line-height: 17px;
      @media only screen and (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 10px;
      }
      .switch {
        cursor: pointer;
        padding: 10px 15px;
        border-radius: 8px;
        transition: 0.3s;
        @media only screen and (max-width: 768px) {
          padding: 8px 10px;
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
        &:hover {
          background-color: $cioccolato500;
          color: $white;
        }
        &--active {
          background-color: $cioccolato500;
          color: $white;
          font-weight: 600;
        }
      }
    }
    &_dropdown {
      position: relative;
      &--selected {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 15px;
        background: $white;
        border-radius: 8px;
        font-size: 14px;
        line-height: 17px;
        @media only screen and (max-width: 768px) {
          padding: 8px 10px;
        }
        svg {
          margin-left: 15px;
          @media only screen and (max-width: 768px) {
            margin-left: 8px;
          }
        }
      }
      &--options {
        box-shadow: 0px -5px 20px rgba(45, 36, 36, 0.1);
        overflow: hidden;
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        background: $white;
        border-radius: 8px;
        .option {
          cursor: pointer;
          text-align: center;
          display: block;
          padding: 8px 10px;
          font-size: 14px;
          line-height: 17px;
          &:hover {
            background-color: $cioccolato500;
            color: $white;
          }
          &--active {
            background-color: $cioccolato500;
            color: $white;
            font-weight: 600;
          }
        }
      }
    }
  }
  ::v-deep .apexcharts-bar-area:hover {
    fill: #5c3d2e !important;
  }
}
</style>
