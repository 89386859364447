import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import Auth from "@/middlewares/auth";
import responseHelper from "@/libs/responseHelper";
import dateTimeHelper from "@/libs/dateTimeHelper";

/**
 * Handling global components importing.
 *
 * @param {Array} directories
 * @returns {Array}
 */
export const importGlobalComponents = (directories = []) => {
  if (directories.length === 0) {
    return [];
  }

  let elements = [];

  directories.forEach((directory) => {
    // #TODO - To add another global directory base we must use IF/ELSE like in next example.
    // Bug Reference: https://github.com/webpack/webpack/issues/4772.
    // Directory can't be set via varible. It has to be set statically as a string (i.e. ../components/base).

    // Import base components.
    /* if (directory === "base") {
      const requireComponent = require.context(
        "../components/base",
        false,
        /[A-Z]\w+\.(vue|js)$/
      );

      elements.push(..._getComponentsInstances(requireComponent));
    } */

    // Import base components.
    if (directory === "global") {
      const requireComponent = require.context(
        "../components/global",
        false,
        /[A-Z]\w+\.(vue|js)$/
      );

      elements.push(..._getComponentsInstances(requireComponent));
    }
  });

  return elements;
};

// Get components name and path from require context.
const _getComponentsInstances = (c) => {
  const records = [];

  c.keys().forEach((fileName) => {
    // Get component config
    const componentConfig = c(fileName);

    const componentName = upperFirst(
      camelCase(
        // Gets the file name regardless of folder depth
        fileName
          .split("/")
          .pop()
          .replace(/\.\w+$/, "")
      )
    );

    records.push({
      name: componentName,
      path: componentConfig.default || componentConfig,
    });
  });

  return records;
};

/**
 * Handling global properties registration.
 *
 * @returns {Array}
 */
export const getGlobalProperties = () => {
  return [
    {
      key: "$appName",
      value: process.env.VUE_APP_NAME || "Cupon",
    },
    {
      key: "$schemeName",
      value: process.env.VUE_APP_SCHEME_NAME || "Partners",
    },
    {
      key: "$apiUrl",
      value: process.env.VUE_APP_API_URL || null,
    },
    {
      key: "$deviceWidth",
      value: window.innerWidth,
    },
    {
      key: "$auth",
      value: new Auth(),
    },
    {
      key: "$res",
      value: responseHelper,
    },
    {
      key: "$dt",
      value: dateTimeHelper,
    },
    {
      key: "$facebookAppId",
      value: process.env.VUE_APP_FACEBOOK_APP_ID,
    },
    {
      key: "$exchangeRateEur",
      value: process.env.VUE_APP_EXCHANGE_RATE_EUR,
    },
  ];
};
