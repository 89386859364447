import axios from "axios";
// Initial state
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  getDashboardStats() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/dashboard`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getGraphStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("/dashboard/widget/graph", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getWidgetCharge({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("/dashboard/widget/charge", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getWidgetCashback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("/dashboard/widget/cashback", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getWidgetSaved({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("/dashboard/widget/saved", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
