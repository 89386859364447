/* eslint-disable no-unused-vars */
import axios from "axios";
// Initial state
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  getStores({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/stores`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSingleStore({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/stores/${payload.id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getStoreCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/store-categories`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getStoreBrands({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/store-brands`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getStoreBrandsStores({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/store-brands/${payload.id}/stores`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
