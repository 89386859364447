// Initial state
const state = {
  isGlobalLoading: false,
};

// Getters
const getters = {};

// Actions
const actions = {};

// Mutations
const mutations = {
  SET_GLOBAL_LOADING(state, payload) {
    state.isGlobalLoading = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
