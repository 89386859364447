<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  computed: {
    layout() {
      // Dynamic layout loading.
      return (this.$route.meta.layout || "default") + "-layout";
    },
  },

  beforeCreate() {
    useHead({
      title: "Cupon",
    });
  },
};
</script>
