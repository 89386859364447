<template>
  <div v-if="coupons.length > 0" class="cpn-table">
    <div class="heading cpn-table heading-prnt">
      <span class="id">ID</span>
      <span class="time">{{ $t("labels.time") }}</span>
      <span class="amount">{{ $t("labels.amount") }}</span>
      <span class="actions">{{ $t("labels.action") }}</span>
    </div>

    <div class="body cpn-table heading-prnt" v-for="c in coupons" :key="c.id">
      <div class="id">
        <span class="id-txt">{{ c.name }}</span>
      </div>

      <div class="time">
        <span class="time-txt initial remove-on-prnt">{{
          formatTime(c.created_at)
        }}</span>
        <span class="time-txt readable">{{
          formatTimeReadable(c.created_at)
        }}</span>
      </div>

      <div class="amount">
        <span class="amount-txt">{{ formatMoney(c.amount) }}</span>
      </div>

      <div class="actions remove-on-prnt">
        <a
          class="status"
          :class="{
            active: c.status === 'active',
            used: c.status === 'used',
            canceled: c.status === 'canceled',
          }"
          @click="
            $emit('deactivateCoupon', {
              status: c.status,
              id: c.id,
            })
          "
          >{{
            c.status === "active"
              ? $t("labels.deactivate")
              : $t(`labels.${c.status}`)
          }}</a
        >
      </div>
    </div>
  </div>

  <div v-else class="no-records">
    <img src="@/assets/img/no-result.png" alt="NoResult" />
    <h5>{{ $t("labels.noResults") }}</h5>
  </div>
</template>

<script>
import { formatCurrency } from "@/libs/helpers";
import { formatDistance, format, parseISO } from "date-fns";
import { enUS, hr } from "date-fns/locale";

export default {
  emits: ["deactivateCoupon"],

  props: {
    coupons: {
      type: Array,
      required: true,
    },
  },

  methods: {
    formatTime(date) {
      const str = `${formatDistance(new Date(), new Date(date), {
        locale: this.$i18n.locale === "hr" ? hr : enUS,
      })}`;

      return this.$i18n.locale === "hr" ? `Prije ${str}` : `${str} ago`;
    },
    formatTimeReadable(date) {
      return format(parseISO(date), "yyyy-MM-dd");
    },
    formatMoney(amount) {
      return `+ ${formatCurrency(amount)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.cpn-table {
  .body,
  .heading {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      font-size: 12px;
      font-weight: 400;
      color: #2d2424;
      font-family: "Rubik", sans-serif;
    }

    .actions {
      width: 150px;
      text-align: right;
    }

    .amount {
      text-align: right;
      width: 200px;
    }

    .time {
      text-align: right;
      width: 150px;
    }

    .id {
      width: calc(100% - 550px);
    }
  }

  .body {
    padding: 10px 5px;

    span {
      font-size: 14px;
    }

    .id-txt {
      font-size: 16px;
      font-weight: 700;
    }

    .amount-txt {
      font-size: 16px;
      font-weight: 600;
      color: #0d8920;
    }

    .id-txt,
    .amount-txt,
    .time-txt {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      padding-right: 10px;
      display: block;

      &.readable {
        display: none;
      }
    }
  }

  .heading {
    margin: 0 0 25px;
    padding: 0 5px 15px;
    border-bottom: 1px solid rgba(224, 192, 151, 0.5);
  }

  .body {
    padding: 0 5px 25px;
    margin: 0 0 25px;
    border-bottom: 1px solid rgba(224, 192, 151, 0.5);

    &:last-child {
      border-bottom: none;
    }

    .status {
      display: block;
      width: fit-content;
      margin-left: auto;
      text-transform: capitalize;
      padding: 8px 20px;
      font-size: 12px;
      font-family: Rubik;
      border-radius: 8px;
      &.canceled {
        color: $white;
        background-color: $red;
      }
      &.used {
        color: $white;
        background-color: $green;
      }
      &.active {
        cursor: pointer;
        color: $wood_bark;
        background-color: rgba(224, 192, 151, 0.2);
        transition: 0.3s;
        &:hover {
          color: $white;
          background-color: $red;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .heading {
      display: none;
    }

    .body {
      flex-wrap: wrap;

      &:first-child {
        border-top: 1px solid rgba(224, 192, 151, 0.5);
      }

      .id {
        width: 100%;
        margin-bottom: 10px;
      }

      .actions {
        width: 90px;
      }

      .amount,
      .time {
        text-align: left;
        width: calc(100% / 2 - 70px);
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .body {
      .actions {
        width: 100%;
        margin-top: 10px;
      }

      .amount,
      .time {
        width: calc(50% - 10px);
      }

      .amount {
        margin-left: 10px;
        text-align: right;
      }

      .status {
        margin: 0;
      }
    }
  }
}

.no-records {
  display: block;
  text-align: center;
  margin: 30px 0 10px;

  img {
    max-width: 255px;
    width: 100%;
  }
}
</style>
