<template>
  <div class="container">
    <transition name="fade">
      <template v-if="isOpened">
        <div class="modal-wrapper">
          <div class="modal-wrapper__inner">
            <div class="modal-wrapper__inner--header">
              <h5>{{ $t("labels.businessHours") }}</h5>
              <div @click="isOpened = false">
                <VueFeather type="x" class="close-icon" />
              </div>
            </div>
            <div class="modal-wrapper__inner--content">
              <ul class="business-hours">
                <li
                  v-for="item in data.store.additional.business_hours"
                  :key="item.name"
                >
                  <p class="small day">{{ item.name }}</p>
                  <p class="small">{{ item.value }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </transition>
    <div class="container-content">
      <div class="container-content__header">
        <a @click="$router.go(-1)" class="back"
          ><svg
            width="8"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1 1 7l6 6"
              stroke="#B85C38"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t("labels.back") }}</a
        >
      </div>
      <div v-if="data && data.store" class="container-content__inner">
        <div class="container-content__inner-grid">
          <div class="box">
            <div>
              <img
                :src="data.store.brand && data.store.brand.avatar"
                :alt="data.store.name"
              />
              <h5 class="title">{{ data.store.name }}</h5>
            </div>
          </div>
          <div class="box">
            <div>
              <p class="trans">Transaction ID: {{ data.id }}</p>
              <p class="date">{{ formatTime(data.created_at) }}</p>
              <h4 class="amount">{{ formatMoney(data.amount, data.type) }}</h4>
            </div>
          </div>
        </div>
        <div v-if="data && data.store" class="container-content__inner-map">
          <div class="box">
            <div>
              <p class="box__title semi-bold">{{ data.store.name }}</p>
              <p class="box__desc">{{ data.store.address }}</p>
              <a
                v-if="
                  data.store.additional &&
                  data.store.additional.business_hours.length
                "
                @click="isOpened = true"
                class="details"
                >{{ $t("labels.shopDetails") }}</a
              >
            </div>
          </div>
          <div>
            <GMapMap
              :center="{ lat: data.store.lat, lng: data.store.lng }"
              :zoom="12"
              style="width: 500px; height: 300px"
            >
              <GMapCluster>
                <GMapMarker
                  :position="{ lat: data.store.lat, lng: data.store.lng }"
                  :clickable="true"
                  :draggable="false"
                />
              </GMapCluster>
            </GMapMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@/libs/helpers";
import { format } from "date-fns";
import { useHead } from "@vueuse/head";
export default {
  name: "TransactionId",
  data() {
    return {
      data: [],
      isOpened: false,
    };
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.transaction_details")}`,
    });
  },
  created() {
    this.$store
      .dispatch("getSingleTransaction", {
        id: this.$route.params.id,
      })
      .then((res) => {
        this.data = res.data.data;
      })
      .catch((err) => {
        return err;
      });
  },
  methods: {
    formatTime(date) {
      return `${format(new Date(date), "dd.MM.yyyy - hh:mm")}`;
    },
    formatMoney(amount, type) {
      return type === "charge"
        ? `- ${formatCurrency(amount)}`
        : `+ ${formatCurrency(amount)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.container {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    display: flex;
    background: rgba(0, 0, 0, 0.3);
    &__inner {
      width: 100%;
      max-width: 500px;
      background: $white;
      padding: 30px;
      height: fit-content;
      margin: auto;
      border-radius: 10px;
      &--header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .close-icon {
          cursor: pointer;
          margin-left: 24px;
        }
      }
      &--content {
        .business-hours {
          padding: 0;
          margin: 19px 0 0 0;
          list-style: none;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 5px;
            border-bottom: 1px solid rgba(224, 192, 151, 0.5);
            .day {
              font-weight: 500;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  &-content {
    &__header {
      display: flex;
      .back {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        svg {
          display: block;
          margin-right: 10px;
        }
      }
    }
    &__inner {
      margin-top: 78px;
      @media (max-width: 768px) {
        margin-top: 30px;
      }
      &-grid {
        display: grid;
        grid-template-columns: 6fr 6fr;
        @media (max-width: 911px) {
          grid-template-columns: 12fr;
        }
        .box {
          box-sizing: border-box;
          padding: 20px 60px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 317px;
          @media (max-width: 768px) {
            padding: 30px 20px;
            min-height: initial;
          }
          .title {
            text-align: center;
            @media (max-width: 768px) {
              font-size: 20px;
            }
          }
          img {
            margin: 0 auto 30px;
            display: block;
            width: 100%;
            max-width: 100px;
          }
          &:first-child {
            &:after {
              content: "";
              display: block;
              background: linear-gradient(
                180deg,
                rgba(224, 192, 151, 0) 0%,
                #e0c097 50.52%,
                rgba(224, 192, 151, 0) 100%
              );
              position: absolute;
              top: 20px;
              right: 0;
              bottom: 0;
              width: 1px;
              @media (max-width: 911px) {
                top: initial;
                left: 0;
                height: 1px;
                width: 100%;
              }
            }
          }
          .trans {
            font-weight: 500;
            text-align: center;
            @media (max-width: 768px) {
              font-size: 14px;
            }
          }
          .date {
            margin-top: 10px;
            text-align: center;
          }
          .amount {
            margin-top: 20px;
            text-align: center;
            white-space: nowrap;
            @media (max-width: 768px) {
              font-size: 24px;
            }
          }
        }
      }
      &-map {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 6fr 6fr;
        background: rgba(224, 192, 151, 0.3);
        border-radius: 12px;
        overflow: hidden;
        @media (max-width: 911px) {
          grid-template-columns: 12fr;
        }
        .box {
          box-sizing: border-box;
          min-height: 240px;
          text-align: center;
          padding: 66px 30px 77px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 768px) {
            padding: 33px 15px 33px;
          }
          &__title {
            margin-bottom: 5px;
            font-size: 20px;
            line-height: 24px;
            color: $wood_bark;
            @media (max-width: 500px) {
              font-size: 16px;
            }
          }
          &__desc {
            @media (max-width: 500px) {
              font-size: 14px;
            }
          }
          .details {
            text-transform: uppercase;
            cursor: pointer;
            margin: 20px auto 0;
            display: block;
            width: fit-content;
            padding: 10px 15px;
            border: 1px solid $calico;
            box-sizing: border-box;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 600;
            font-family: Rubik;
            background-color: $white;
            @media (max-width: 500px) {
              padding: 8px 10px;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
        .vue-map-container {
          height: 100%;
          min-height: 250px;
        }
      }
    }
  }
}
</style>
