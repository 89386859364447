<template>
  <div class="container">
    <div class="container-content">
      <div class="container-content__header">
        <a @click="$router.go(-1)" class="back"
          ><svg
            width="8"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1 1 7l6 6"
              stroke="#B85C38"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t("labels.back") }}</a
        >
        <div class="container-content__header--action">
          <div class="actions">
            <!--<div class="actions__inner" @click="print">
              <a>{{ $t("labels.print") }}</a>
              <svg
                class="icon"
                width="18"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.37 2.933h-2.974L12.619.52a.773.773 0 0 0-.72-.52H6.082a.749.749 0 0 0-.72.52l-.777 2.413H1.63C.72 2.933 0 3.657 0 4.529v5.309c0 .89.739 1.596 1.63 1.596h.265l-1.156 3.62a.672.672 0 0 0 .114.649c.132.186.36.297.606.297h15.082a.768.768 0 0 0 .606-.297.725.725 0 0 0 .114-.65l-1.156-3.6h.265c.91 0 1.63-.724 1.63-1.597V4.53c0-.891-.739-1.596-1.63-1.596Zm-1.155 1.874a.86.86 0 0 1 .871.854.86.86 0 0 1-.871.854.873.873 0 0 1-.872-.854c0-.464.398-.854.872-.854ZM6.65 1.467h4.717l.474 1.447H6.177l.474-1.448ZM2.482 14.533 4.377 8.63h9.246l1.895 5.903H2.482Z"
                  fill="#5C3D2E"
                />
                <path
                  d="M6.234 10.988h5.513c.341 0 .607-.26.607-.594a.593.593 0 0 0-.607-.594H6.234a.593.593 0 0 0-.607.594c0 .335.284.594.607.594ZM12.543 12.158H5.457a.593.593 0 0 0-.606.594c0 .334.265.594.606.594h7.086c.341 0 .607-.26.607-.594a.604.604 0 0 0-.607-.594Z"
                  fill="#5C3D2E"
                />
              </svg>
            </div>-->
            <!-- <div class="actions__inner">
              <a>{{ $t("labels.export") }}</a>
              <svg
                class="icon"
                width="15"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="m8.326.344 3.183 3.121c1.281 1.284-.64 3.21-1.921 1.926l-.013-.013c-.394-.4-.716-.268-.716.297v2.498c0 .75-.612 1.359-1.367 1.359h.016a1.362 1.362 0 0 1-1.367-1.36V5.676c0-.564-.32-.698-.716-.297l-.013.013C4.132 6.675 2.21 4.75 3.491 3.465L6.63.35A1.21 1.21 0 0 1 8.325.344ZM3.067 13.277h8.866c.19 0 .34-.151.34-.338v-1.705a1.363 1.363 0 0 1 2.727 0v3.404C15 15.39 14.39 16 13.636 16H1.364C.61 16 0 15.39 0 14.638v-3.404a1.363 1.363 0 0 1 2.727 0v1.705a.34.34 0 0 0 .34.338Z"
                  fill="#5C3D2E"
                />
              </svg>
            </div> -->
          </div>
        </div>
      </div>
      <div class="container-content__inner">
        <SkeletonLoader
          v-if="$store.state.state.isGlobalLoading"
          type="shop-details"
        />
        <template v-else>
          <img
            class="brand-logo"
            :src="details && details.brand && details.brand.avatar"
            :alt="details && details.brand && details.brand.name"
          />
          <h4 class="brand-name">{{ details.name }}</h4>
          <h5 class="brand-address">{{ details.address }}</h5>
          <p
            v-for="phone in details.additional.contacts"
            :key="phone.value"
            class="brand-phone large"
          >
            <svg
              width="18"
              height="20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.603 8.021c1.45-1.132 3.228-1.468 2.714-3.427-.513-1.96-1.582-6.48-4.535-3.73-8.372 7.8 3.968 24.286 13.04 17.558 3.221-2.385-.676-4.551-2.352-5.543-1.679-.992-2.407.758-3.789 1.984C7.963 16.383 2.8 9.43 4.603 8.021Z"
                fill="#B85C38"
              />
            </svg>
            {{ $t("labels.phone") }} {{ phone.value }}
          </p>
          <div class="divider" />
          <div class="brand-info">
            <div v-if="details.additional.business_hours.length > 0">
              <h5>
                {{ $t("labels.businessHours") }}
                <svg
                  width="14"
                  height="14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6 7A5.6 5.6 0 1 1 1.4 7a5.6 5.6 0 0 1 11.2 0ZM14 7A7 7 0 1 1 0 7a7 7 0 0 1 14 0ZM7.7 4.2a.7.7 0 1 0-1.4 0V7a.7.7 0 0 0 .205.495l2.1 2.1a.7.7 0 0 0 .99-.99L7.7 6.71V4.2Z"
                    fill="#000"
                  />
                </svg>
              </h5>
              <ul class="business-hours">
                <li
                  v-for="item in details.additional.business_hours"
                  :key="item.name"
                >
                  <p class="small day">{{ item.name }}</p>
                  <p class="small">{{ item.value }}</p>
                </li>
              </ul>
            </div>
            <GMapMap
              :center="{ lat: details.lat, lng: details.lng }"
              :zoom="12"
              style="width: 500px; height: 300px"
            >
              <GMapCluster>
                <GMapMarker
                  :position="{ lat: details.lat, lng: details.lng }"
                  :clickable="true"
                  :draggable="false"
                />
              </GMapCluster>
            </GMapMap>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  name: "ShopIdStore",
  data() {
    return {
      details: null,
    };
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.show_details")}`,
    });
  },
  created() {
    this.getShopDetails();
  },
  methods: {
    print() {
      window.print();
    },
    getShopDetails() {
      this.$store.commit("SET_GLOBAL_LOADING", true);
      this.$store
        .dispatch("getSingleStore", {
          id: this.$route.params.shopId,
        })
        .then((res) => {
          setTimeout(
            () => this.$store.commit("SET_GLOBAL_LOADING", false),
            1500
          );
          this.details = res.data.data;
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &-content {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .back {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        svg {
          display: block;
          margin-right: 10px;
        }
      }
      &--action {
        display: flex;
        align-items: center;
        .actions {
          display: flex;
          align-items: center;
          &__inner {
            cursor: pointer;
            display: flex;
            align-items: center;
            &:first-child {
              margin-right: 30px;
              @media (max-width: 500px) {
                margin-right: 15px;
              }
            }
            a {
              font-family: Rubik;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
            .icon {
              margin-left: 10px;
            }
          }
        }
      }
    }
    &__inner {
      margin-top: 70px;
      .brand-logo {
        margin-bottom: 32px;
        display: block;
        width: 100%;
        max-width: 285px;
        max-height: 100px;
        object-fit: contain;
        object-position: left;
      }
      .brand-name {
        margin-bottom: 9px;
        font-weight: 600;
        font-size: 36px;
        color: $wood-bark;
        @media (max-width: 768px) {
          font-size: 24px;
          margin-bottom: 0;
        }
      }
      .brand-address {
        margin-bottom: 41px;
        font-weight: 400;
        color: $cioccolato500;
        @media (max-width: 768px) {
          margin-bottom: 20px;
          font-size: 20px;
          line-height: 28px;
        }
      }
      .brand-phone {
        display: flex;
        align-items: center;
        color: $cioccolato500;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        @media (max-width: 768px) {
          font-size: 16px;
        }
        svg {
          display: block;
          margin-right: 16px;
          @media (max-width: 768px) {
            margin-right: 10px;
          }
        }
      }
      .divider {
        margin: 40px 0 80px 0;
        width: 100%;
        height: 1px;
        background-color: $calico;
        opacity: 0.5;
        @media (max-width: 768px) {
          margin: 25px 0;
        }
      }
      .brand-info {
        display: grid;
        grid-template-columns: 5fr 7fr;
        grid-gap: 60px;
        @media (max-width: 768px) {
          grid-template-columns: 12fr;
          grid-gap: 30px;
        }
        h5 {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          @media (max-width: 768px) {
            font-size: 18px;
            align-items: center;
          }
          svg {
            display: block;
            margin-left: 10px;
          }
        }
        .business-hours {
          padding: 0;
          margin: 19px 0 0 0;
          list-style: none;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 5px;
            border-bottom: 1px solid rgba(224, 192, 151, 0.5);
            .day {
              font-weight: 500;
              margin-right: 10px;
            }
          }
        }
        .vue-map-container {
          min-height: 350px;
        }
      }
    }
  }
}
</style>
